<template>
  <div class="common-width">
    <!--2021编译系统设计赛-->
    <el-tabs
      v-model="activeName"
      @tab-click="callback"
      class="common-width"
        v-if="name.indexOf('2021')!=-1&& name.indexOf('编译')!=-1"
 
    >
      <el-tab-pane label="大赛时刻" name="first">
        <div class="tabs-tabpane-width add-img">
          <p class="pad-index">
            全国大学生计算机系统能力大赛编译系统设计赛(以下简称“大赛”)是由教育部高等学校计算机类专业教学指导委员会和系统能力培养研究专家组共同发起，以学科竞赛推动专
            业建设和计算机领域创新人才培养体系改革、培育我国高端芯片及核心系统的技术突破与产业化后备人才为目标,面向高校大学生举办的全国性大赛。
          </p>
          <p class="pad-index">
            编译系统设计赛要求各参赛队综合运用各种知识（包括但不局限于编译技术、操作系统、计算机体系结构等），构思并实现一个综合性的编译系统，以展示面向特定目标平台的编译器构造与编译优化的能力。
          </p>
          <p
            class="look-del"
            style="text-align: center; font-size: 30px; margin-top: 20px"
          >
            全国总决赛获奖结果
          </p>
          <img src="../images/2021compiler1.png" />
          <img src="../images/2021compiler2.png" />
          <img src="../images/2021compiler3.png" />
          <p
            class="look-del"
            style="text-align: center; font-size: 30px; margin-top: 20px"
          >
            决赛入围名单
          </p>
          <img src="../images/oldjs1.png" />
          <img src="../images/oldjs2.png" />
          <img src="../images/oldjs3.png" />
          <p class="look-del">本届大赛亮点:</p>
          <p class="pad-index">1.各高校初赛队伍数量不做限制。</p>
          <p class="pad-index">2.支持使用Java语言实现编译器。</p>
          <p class="pad-index">
            3.引入个人等级证书,大赛设置全国大学生计算机系统大赛编译系统设计赛等级标准体系，参赛队作品达到相应等级水平，大赛将向该参赛队所有选手颁发相应的等级证书。
          </p>
          <p class="pad-index">4.一等奖和二等奖奖金额度大幅增加。</p>
          <a
            href="https://course.educg.net/sv2/indexexp/contest/contest.jsp?contestID=7pGgalB8drk"
            target="_blank"
            ><el-button
              type="primary"
              style="width: 112px; margin: 20px auto; display: block"
              >比赛已结束</el-button
            ></a
          >
        </div>
      </el-tab-pane>
      <el-tab-pane label="大赛章程与通知" name="second">
        <div class="tabs-tabpane-width">
          <p class="look-del look-title">
            2021全国大学生计算机系统能力培养大赛编译系统设计赛的信息
          </p>
          <a-list
            item-layout="horizontal"
            :data-source="dataBy"
            :split="false"
            class="teshu"
          >
            <a-list-item slot="renderItem" slot-scope="item">
              <a-list-item-meta description="">
                <a slot="title" :href="item.href" target="_blank">
                  <a-icon type="link" /><span style="margin-left: 10px">{{
                    item.title
                  }}</span>
                </a>
              </a-list-item-meta>
            </a-list-item>
          </a-list>
        </div>
      </el-tab-pane>
      <el-tab-pane label="大赛要求与比赛内容" name="third">
        <div class="tabs-tabpane-width">
          <p class="look-del look-title">竞赛要求:</p>
          <div>
            <p class="pad-left">
              1. 编译器目标平台： 树莓派4B（ARM Cortex-A72 CPU，2GB LPDDR4 SDRAM
              内存） ，报名截止后，大赛组委会给参赛队伍邮寄树莓派。
              <a
                target="_blank"
                href="https://gitlab.eduxiji.net/nscscc/compiler2021/-/blob/master/%E6%A0%91%E8%8E%93%E6%B4%BE%E7%9B%B8%E5%85%B3%E5%BC%80%E5%8F%91%E6%96%87%E6%A1%A3.pdf"
                >树莓派相关开发文档.pdf</a
              >。 同时推荐大家使用华为云鲲鹏服务器进行开发。
            </p>
            <p class="pad-left">
              2. 语言定义与竞赛要求：
              <a
                target="_blank"
                href="https://gitlab.eduxiji.net/nscscc/compiler2022/-/blob/master/SysY2022%E8%AF%AD%E8%A8%80%E5%AE%9A%E4%B9%89-V1.pdf"
                >语言定义</a
              >、
              <a
                target="_blank"
                href="https://gitlab.eduxiji.net/nscscc/compiler2022/-/blob/master/SysY2022%E8%BF%90%E8%A1%8C%E6%97%B6%E5%BA%93-V1.pdf"
                >运行时库</a
              >。
            </p>
            <p class="pad-left">
              3.
              git协作：要求使用git进行协作开发，登录之后，点击导航栏的“gitlab”即可直接登录gitlab，
              <a
                target="_blank"
                href="https://gitlab.eduxiji.net/nscscc/compiler2021/-/blob/master/GitLab%E5%9F%BA%E7%A1%80%E4%BD%BF%E7%94%A8%E6%96%B9%E5%BC%8F.pdf"
                >代码托管平台使用文档与注册规范 </a
              >。
            </p>
            <p class="pad-left">
              4.
              <a
                target="_blank"
                href="https://gitlab.eduxiji.net/nscscc/compiler2021/-/blob/master/GitLab%E5%9F%BA%E7%A1%80%E4%BD%BF%E7%94%A8%E6%96%B9%E5%BC%8F.pdf"
              >
                系统运行时库、 功能测试用例、 性能测试用例</a
              >。
            </p>
            <p class="pad-left">
              5.
              <a
                target="_blank"
                href="https://gitlab.eduxiji.net/nscscc/compiler2021/-/blob/master/%E5%A4%A7%E8%B5%9B%E6%8F%90%E4%BA%A4%E8%AF%B4%E6%98%8Ev2.pdf"
              >
                大赛提交说明</a
              >。
            </p>
          </div>
          <p class="look-del look-title look-top">
            比赛内容: 开发支持特定语言、面向ARM硬件平台的综合性编译系统
          </p>
          <div>
            <p class="pad-left">
              1.基于C、C++、Java语言开发，能够在Ubuntu18.04(64位)操作系统的x86评测服务器上编译运行。
            </p>
            <p class="pad-left">
              2.
              能够将符合自定义程序设计语言SysY2021的测试程序编译为ARM汇编语言程序。
            </p>
            <p class="pad-left">
              3. 通过在Raspbery 4B
              上运行汇编链接后的二进制程序，测试程序功能的正确性和运行效率，来评价参赛队开发的编译器的功能正确性和优化效果。
              <img
                style="display: inline-block"
                src="../images/neirong.png"
                class="tab-img"
              />
            </p>
          </div>
        </div>
      </el-tab-pane>
      <el-tab-pane label="技术报告" name="five">
        <div class="tabs-tabpane-width">
          <div v-for="(item, index) in listAry" :key="index">
            <p class="look-del">{{ item.title }}</p>
            <img :src="item.img" class="tab-imgD" v-if="item.img != ''" />
            <p class="pad-left">1. 时间：{{ item.time }}</p>
            <p class="pad-left" v-if="item.href1">
              2. 培训日程与直播地址：
              <a target="_blank" :href="item.href1"> 点击查看</a>
            </p>

            <p class="pad-left">
              3. 直播回放：
              <a target="_blank" :href="item.href2">点击查看回放</a>
            </p>
            <a-table
              bordered
              :columns="columnsBy"
              :data-source="item.dataColumns"
              style="margin-top: 10px"
              :pagination="false"
              v-if="item.dataColumns.length != 0"
            >
              <a
                slot="action"
                slot-scope="text, record"
                target="_blank"
                :href="record.href3"
                v-if="record.href3 != ''"
                >{{ record.hrefName }}</a
              >
            </a-table>
          </div>
        </div>
      </el-tab-pane>
      <el-tab-pane label="作品开源" name="six">
        <div class="tabs-tabpane-width">
          <p class="look-del look-title">参赛作品开源地址</p>
          <a-table
            bordered
            :columns="columnsK"
            :data-source="worklist"
            style="margin-top: 10px"
            :pagination="false"
          >
            <a slot="action" slot-scope="item" :href="item" target="_blank">{{
              item
            }}</a>
          </a-table>
        </div>
      </el-tab-pane>
      <el-tab-pane label="技术支持" name="seven">
        <div class="tabs-tabpane-width">
          <p class="look-del look-title">技术支持</p>
          <p class="pad-left">
            参赛队员官方技术支持QQ群：<a
              target="_blank"
              href="https://qm.qq.com/cgi-bin/qm/qr?k=A8HT6ffhc2H7ng7XEJ8pDnntlBgUKRQs&authKey=%2BERv6TPdGkVVZdoV93Eksz8sLF3T5Un14PcLlRttdxs9d%2B8ynNSOdQ%3D%3D&group_code=700621252"
              >700621252</a
            >
            【请注明：学校+姓名】，在群内即时获取技术支持、了解大赛信息。
          </p>
          <p class="pad-left">
            指导教师QQ群：<a
              target="_blank"
              href="https://qm.qq.com/cgi-bin/qm/qr?k=Q6SgrbBJHkBW60TJaYPzCNTOzRnZ-hf1&authKey=4lMK%2FNf2Qv42%2B%2BejxcA1SlezR4Z3Y8%2FbTK6mqXD4IdEOQcINq%2FopXg%3D%3D&group_code=719489238"
              >719489238</a
            >
            【仅限 高校教师，请注明：学校+姓名】，了解大赛信息，平台开课。
          </p>
          <p class="look-del look-title look-top">软件开发工具与设计资料</p>
          <p class="pad-left">
            <a
              target="_blank"
              href="https://gitlab.eduxiji.net/nscscc/compiler2022/-/blob/master/SysY2022%E8%AF%AD%E8%A8%80%E5%AE%9A%E4%B9%89-V1.pdf"
              >1.SysY2022 程序设计语言规范、文法及说明</a
            >
          </p>
          <p class="pad-left">
            <a
              target="_blank"
              href="https://gitlab.eduxiji.net/nscscc/compiler2021/-/blob/master/%E6%A0%91%E8%8E%93%E6%B4%BE%E7%9B%B8%E5%85%B3%E5%BC%80%E5%8F%91%E6%96%87%E6%A1%A3.pdf"
              >2.树莓派(Raspberry 4B)使用与开发指南</a
            >
          </p>
          <p class="pad-left">
            <a
              target="_blank"
              href="https://static.docs.arm.com/ddi0406/c/DDI0406C_C_arm_architecture_reference_manual.pdf"
              >3.ARM_Architecture_Reference_Manual_ARMv7-A_and_ARMv7-R_edition</a
            >
          </p>
          <p class="pad-left">
            <a
              target="_blank"
              href="https://gitlab.eduxiji.net/nscscc/compiler2021/-/blob/master/ARM%E5%92%8CThumb-2%E6%8C%87%E4%BB%A4%E9%9B%86%E5%BF%AB%E9%80%9F%E5%8F%82%E8%80%83%E5%8D%A1.pdf"
              >4.ARM和Thumb-2指令集快速参考卡</a
            >
          </p>
          <p class="pad-left">
            <a target="_blank" href="https://c9x.me/compile/bib/"
              >5.Resources for Amateur Compiler Writers</a
            >
          </p>
          <p class="pad-left">
            <a target="_blank" href="https://pan.educg.net/#/s/V2oiq?path=%2F"
              >6.编译大赛评测镜像</a
            >
          </p>
          <p class="pad-left">
            <a
              target="_blank"
              href="https://pan.educg.net/#/s/leAC9/pdf?name=%E5%85%B3%E4%BA%8E%E9%9A%90%E8%97%8F%E6%B5%8B%E8%AF%95%E7%94%A8%E4%BE%8B%E7%9A%84%E6%8F%90%E7%A4%BA.pdf"
              >7.关于隐藏测试用例的提示</a
            >
          </p>
        </div>
      </el-tab-pane>
      <el-tab-pane label="常见问题" name="eight">
        <div class="tabs-tabpane-width addLi">
          <ul>
            <li>
              <h3>树莓派赛后是否收回？</h3>
              <p class="pad-left">树莓派由华为赞助，赛后不收回。</p>
            </li>
            <li>
              <h3>学生证丢了，是否可以用身份证替代？</h3>
              <p class="pad-left">
                身份证不能证明本科生身份，可以用一卡通，或者学院出个证明即可。主要是能够证明本人来自哪所高校、什么时候入学。
              </p>
            </li>
          </ul>
        </div>
      </el-tab-pane>
    </el-tabs>
    <!--2020编译系统设计赛   -->
    <el-tabs v-model="activeName" @tab-click="callback" class="common-width" v-if="name.indexOf('2020')!=-1&& name.indexOf('编译')!=-1"  >
      <el-tab-pane label="大赛时刻" name="first">
        <div class="tabs-tabpane-width add-img addLia">
          <p class="pad-index">
            全国大学生计算机系统能力大赛编译系统设计赛(以下简称“大赛”)是由教育部高等学校计算机类专业教学指导委员会和系统能力培养研究专家组共同发起，以学科竞赛推动专
            业建设和计算机领域创新人才培养体系改革、培育我国高端芯片及核心系统的技术突破与产业化后备人才为目标,面向高校大学生举办的全国性大赛。
          </p>
          <p class="pad-index">
            编译系统设计赛要求各参赛队综合运用各种知识（包括但不局限于编译技术、操作系统、计算机体系结构等），构思并实现一个综合性的编译系统，以展示面向特定目标平台的编译器构造与编译优化的能力。
          </p>
          <p class="look-del look-title look-top">赛后评测通道链接</p>
          <a-table
            bordered
            :columns="columnsSh"
            :data-source="workSh"
            style="margin-top: 10px"
            :pagination="false"
          >
            <a
              slot="action"
              slot-scope="item, record"
              :href="record.url"
              target="_blank"
              >{{ item }}</a
            >
          </a-table>
          <p class="look-del look-title look-top">点击下方链接查看更多信息</p>
          <ul>
            <li>
              <a
                href="https://mp.weixin.qq.com/s?__biz=MzI2MjI1NDMxOQ==&mid=2247485743&idx=1&sn=c389b98dbbfaf25c7b4f94ccdc00028e&chksm=ea4cb2afdd3b3bb9076aa17804490a0856e183a072a736f86495833e4ab7361511643fce8d7b&mpshare=1&scene=1&srcid=0915EnQHK6DWlj4hR9z9CrYV&sharer_sharetime=1600134068664&sharer_shareid=cd036bdb56c3cd05d0ba3fef7a8bd659&version=3.0.30.2006&platform=win&rd2werd=1#wechat_redirect"
                target="_blank"
                >陈文光：首届全国大学生计算机系统能力大赛编译系统设计赛作品总结</a
              >
            </li>

            <li>
              <a
                href="https://mp.weixin.qq.com/s?__biz=MzI2MjI1NDMxOQ==&mid=2247485632&idx=2&sn=15820e3d7641d20b5a3b92253f0beba6&chksm=ea4cb340dd3b3a5691bdce97c8b3af0c5c3722e221f85bc01668ef19e0793c8aceedd7505e73&mpshare=1&srcid=0901y9nwSG6N8BNxfAeboIuf&sharer_sharetime=1598955342399&sharer_shareid=c705d4576abcb79b9d88e5466145fe91&scene=1&subscene=10000&clicktime=1598961541&enterid=1598961541&ascene=1&devicetype=android-28&version=3.0.28.2802&nettype=WIFI&abtest_cookie=AAACAA%3D%3D&lang=zh_CN&exportkey=AWBWzio2aG1uKjZW1lw7OtE%3D&pass_ticket=xyFa1FVzkEJi6HNUvuF%2F8CXPlfJhbGSYxAAWbsCTybay89V0iFhOkULvnfRTaesc&wx_header=1&platform=win"
                target="_blank"
              >
                2020年全国大学生计算机系统能力大赛编译系统设计赛回顾与总结
              </a>
            </li>

            <li>
              <a
                href="https://mp.weixin.qq.com/s?__biz=MzI2MjI1NDMxOQ==&mid=2247485555&idx=1&sn=980d5ab7394b8bd44655269e25d396a9&chksm=ea4cb3f3dd3b3ae5b994ec271f6f3a3c0d40ecace5667abde90ffd8e32f3e2d765dd6db8f135&mpshare=1&srcid=0828g4M42ojgK49rukxC102t&sharer_sharetime=1598603783941&sharer_shareid=cd036bdb56c3cd05d0ba3fef7a8bd659&scene=1&subscene=10000&clicktime=1598605373&enterid=1598605373&ascene=1&devicetype=android-28&version=3.0.27.2701&nettype=cmnet&abtest_cookie=AAACAA%3D%3D&lang=zh_CN&exportkey=Ac8WED8RGVZCRHYT6zfDifc%3D&pass_ticket=NDaRf6DyjQ83c68jATC6ms62viMN82WF%2BZXNXw%2FRuGzS%2BkCUi4UEf0hz4%2FpWfwxF&wx_header=1&platform=win"
                target="_blank"
              >
                首届全国大学生计算机系统能力大赛编译系统设计赛圆满落幕
              </a>
            </li>
          </ul>
          <img
            src="../images/chengji.364b130b.png"
          />
          <h3  style="text-align: center; font-size: 30px; margin-top: 20px">入围决赛队伍名单</h3>
          <img
            src="../images/1596856307489005388.png"
          />
          <h3  style="text-align: center; font-size: 30px; margin-top: 20px">决赛评审暨颁奖典礼</h3>
          <img
            src="../images/640.9145077e.png"
           
          />
          <p style='width:80%;margin:0 auto'>
                <img
            src="../images/IMG_20200821_172526.150c7687.jpg"
                     style='width:50%;display:inline-block'
          />
              <img
            src="../images/IMG_20200821_075805.e35a6568.jpg"
             style='width:50%;display:inline-block'
          />
          </p>
                    <p style='width:80%;margin:0 auto'>
                <img
            src="../images/IMG_20200821_085328.ce07eddd.jpg"
                   style='width:50%;display:inline-block'
           
          />
              <img
            src="../images/IMG_20200821_142940.f07a8825.jpg"
               style='width:50%;display:inline-block'
          />
          </p>
          <img src="../images/IMG_20200821_175701.a6ae15a7.jpg">
          <p class="look-del look-title look-top" style='text-align:center'>===报名已经截止===</p>
          <p class="look-del look-title look-top">报名:(下面两种报名方式二选一)</p>
          <p class="pad-index" style='text-indent:0'>1.下载报名表，编译系统设计赛报名表.xlsx填写后，发至组委会邮箱zhourui@hzbook.com 。</p>
           <p class="pad-index" style='text-indent:0'>2.填写右方的在线报名表，报名截止日前，都可以修改报名信息。请大家注意：在线报名，以队伍为单位报名，大赛开启以后，报名账号同时作为提交作品的账号。</p>
            <p class="pad-index" style='text-indent:0'>3.有关报名的补充说明：</p>
            <p class='addLi' style='padding-left:10px;'>
              <ul>
                <li>因疫情影响，参赛队可以先填写报名表以线上或邮件形式发送给组委会，确认参赛。待疫情过后，补盖章手续。</li>
                <li>由学院或学校相关部门盖章均可，目的是确认参赛者报名时为该校本科生。</li>
                <li>报名表内的负责人信息，填写带队教师的信息。</li>
                <li>签字处应根据学校或学院相关规定，由相关学院/系领导盖章。</li>
                <li>除教师证外，教师还可以通过提供员工卡、校园一卡通或由学院开具的工作证明等有效证件或证明来说明身份。</li>
                <li>学生证可以用一卡通等替代。</li>
              </ul>
            </p>
        </div>
      </el-tab-pane>
      <el-tab-pane label="大赛章程与通知" name="second">
        <div class="tabs-tabpane-width">
          <p class="look-del look-title">
            全国大学生计算机系统能力大赛编译系统设计赛全国总决赛通知
          </p>
     <p class="pad-left">全国总决赛时间安排如下：</p>
          <p class='addLi' style='padding-left:10px;'>
              <ul>
                <li>8月19日 8:00 竞赛官网发布决赛试题，各决赛队开始线上答题并提交</li>
                <li>8月20日 18:00 线上比赛结束，截止提交，并冻结代码</li>
                <li>8月21日 8:00-15:00 决赛队线上答辩</li>
                <li>8月21日 16:00-18:00 全国总决赛颁奖典礼（线上）</li>
                <!-- <li>除教师证外，教师还可以通过提供员工卡、校园一卡通或由学院开具的工作证明等有效证件或证明来说明身份。</li>
                <li>学生证可以用一卡通等替代。</li> -->
              </ul>
            </p>
          <p class="pad-left">详情参考: <a href='https://gitlab.eduxiji.net/nscscc/docs/-/blob/master/2020%E5%85%A8%E5%9B%BD%E5%A4%A7%E5%AD%A6%E7%94%9F%E7%B3%BB%E7%BB%9F%E8%83%BD%E5%8A%9B%E5%A4%A7%E8%B5%9B%E7%BC%96%E8%AF%91%E7%B3%BB%E7%BB%9F%E8%AE%BE%E8%AE%A1%E8%B5%9B%E6%80%BB%E5%86%B3%E8%B5%9B%E7%AD%94%E8%BE%A9%E7%BB%86%E5%88%99.pdf' target="_blank">2020全国大学生系统能力大赛编译系统设计赛总决赛答辩细则</a></p>
          <p class="look-del look-title look-top">
            大赛章程
          </p>
              <p class="pad-left">主办单位:全国高等学校计算机教育研究会、清华大学、北京大学、北京航空航天大学、国防科学技术大学、南京大学、上海交通大学、浙江大学、中国科学技术大学</p>
              <p class="pad-left">承办单位:西北工业大学</p>
              <p class="pad-left">协办单位:华为技术有限公司、机械工业出版社华章分社</p>
              <p class="pad-left">大赛章程：<a href='https://gitlab.eduxiji.net/nscscc/docs/-/blob/master/%E5%85%A8%E5%9B%BD%E5%A4%A7%E5%AD%A6%E7%94%9F%E7%BC%96%E8%AF%91%E7%B3%BB%E7%BB%9F%E8%AE%BE%E8%AE%A1%E8%B5%9B__%E7%AB%A0%E7%A8%8B.pdf' target="_blank">全国大学生编译系统设计赛——章程.pdf</a></p>
              <p class="pad-left">技术方案：<a href='https://gitlab.eduxiji.net/nscscc/docs/-/blob/master/2020%E5%85%A8%E5%9B%BD%E5%A4%A7%E5%AD%A6%E7%94%9F%E8%AE%A1%E7%AE%97%E6%9C%BA%E7%B3%BB%E7%BB%9F%E8%83%BD%E5%8A%9B%E5%A4%A7%E8%B5%9B-%E7%BC%96%E8%AF%91%E7%B3%BB%E7%BB%9F%E8%AE%BE%E8%AE%A1%E8%B5%9B%E6%8A%80%E6%9C%AF%E6%96%B9%E6%A1%88.pdf' target="_blank">2020系统能力大赛-编译系统设计赛技术方案-v10.pdf</a></p>

        </div>
      </el-tab-pane>
      <el-tab-pane label="竞赛要求" name="third">
        <div class="tabs-tabpane-width">
          <p class="look-del look-title">竞赛要求:</p>
          <div>
            <p class="pad-left">
              1. 编译器目标平台： 树莓派4B（ARM Cortex-A72 CPU，2GB LPDDR4 SDRAM
              内存） ，报名截止后，大赛组委会给参赛队伍邮寄树莓派。
              <a
                target="_blank"
                href="https://gitlab.eduxiji.net/nscscc/compiler2021/-/blob/master/%E6%A0%91%E8%8E%93%E6%B4%BE%E7%9B%B8%E5%85%B3%E5%BC%80%E5%8F%91%E6%96%87%E6%A1%A3.pdf"
                >树莓派相关开发文档.pdf</a
              >。 同时推荐大家使用华为云鲲鹏服务器进行开发。
            </p>
            <p class="pad-left">
              2. 语言定义与竞赛要求：
              <a
                target="_blank"
                href="https://gitlab.eduxiji.net/nscscc/compiler2022/-/blob/master/SysY2022%E8%AF%AD%E8%A8%80%E5%AE%9A%E4%B9%89-V1.pdf"
                >语言定义</a
              >、
              <a
                target="_blank"
                href="https://gitlab.eduxiji.net/nscscc/compiler2022/-/blob/master/SysY2022%E8%BF%90%E8%A1%8C%E6%97%B6%E5%BA%93-V1.pdf"
                >运行时库</a
              >。
            </p>
            <p class="pad-left">
              3.
              git协作：要求使用git进行协作开发，登录之后，点击导航栏的“gitlab”即可直接登录gitlab，
              <a
                target="_blank"
                href="https://gitlab.eduxiji.net/nscscc/compiler2021/-/blob/master/GitLab%E5%9F%BA%E7%A1%80%E4%BD%BF%E7%94%A8%E6%96%B9%E5%BC%8F.pdf"
                >代码托管平台使用文档与注册规范 </a
              >。
            </p>
            <p class="pad-left">
              4.
              <a
                target="_blank"
                href="https://gitlab.eduxiji.net/nscscc/compiler2021/-/blob/master/GitLab%E5%9F%BA%E7%A1%80%E4%BD%BF%E7%94%A8%E6%96%B9%E5%BC%8F.pdf"
              >
                系统运行时库、 功能测试用例、 性能测试用例</a
              >。
            </p>
            <p class="pad-left">
              5.
              <a
                target="_blank"
                href="https://gitlab.eduxiji.net/nscscc/compiler2021/-/blob/master/%E5%A4%A7%E8%B5%9B%E6%8F%90%E4%BA%A4%E8%AF%B4%E6%98%8Ev2.pdf"
              >
                大赛提交说明</a
              >。
            </p>
          </div>
          <p class="look-del look-title look-top">
            比赛内容: 开发支持特定语言、面向ARM硬件平台的综合性编译系统
          </p>
          <div>
            <p class="pad-left">
              1.基于C、C++、Java语言开发，能够在Ubuntu18.04(64位)操作系统的x86评测服务器上编译运行。
            </p>
            <p class="pad-left">
              2.
              能够将符合自定义程序设计语言SysY2021的测试程序编译为ARM汇编语言程序。
            </p>
            <p class="pad-left">
              3. 通过在Raspbery 4B
              上运行汇编链接后的二进制程序，测试程序功能的正确性和运行效率，来评价参赛队开发的编译器的功能正确性和优化效果。
              <img
                style="display: inline-block"
                src="../images/neirong.png"
                class="tab-img"
              />
            </p>
               <p class="pad-left">
              技术支持:各参赛队请加入官方技术支持QQ群：<a href='https://jq.qq.com/?_wv=1027&k=5v3WzG5' target="_blank">700621252</a>【请注明：学校+姓名】，在群内即时获取技术支持、了解大赛的信息。有关大赛的技术分享、常见问题，请访问在线社区：
          <a href='https://www.eduxiji.net/t/Compile_the_contest' target="_blank">https://www.eduxiji.net</a>。
          
            </p>
            <p class="pad-left">
              带队教师:准备自己出题训练或者日常教学，<a href='http://www.educg.net/' target="_blank">希冀平台官网 www.educg.net
，</a>请加入QQ群：<a href='https://jq.qq.com/?_wv=1027&k=5uzGtjU' target="_blank">719489238</a>【仅限 高校教师，请注明：学校+姓名】， <a href='http://course.educg.net/' target="_blank">申请开课</a>。
  
          
            </p>
          </div>
        </div>
      </el-tab-pane>

      <el-tab-pane label="作品开源" name="six">
        <div class="tabs-tabpane-width">
          <p class="look-del look-title">参赛作品开源地址</p>
          <a-table
            bordered
            :columns="columnsK"
            :data-source="workByt"
            style="margin-top: 10px"
            :pagination="false"
          >
            <a slot="action" slot-scope="item" :href="item" target="_blank">{{
              item
            }}</a>
          </a-table>
        </div>
      </el-tab-pane>
      <el-tab-pane label="技术报告" name="seven">
        <div class="tabs-tabpane-width">
          <p class="look-del look-title">全国总决赛报告</p>
          <p class="pad-left">
            报告人：华为公司
            <a
              target="_blank"
              href="https://gitlab.eduxiji.net/nscscc/docs/-/raw/master/AIOT%E6%97%B6%E4%BB%A3%E6%B7%B1%E5%BA%A6%E5%AD%A6%E4%B9%A0%E7%BC%96%E8%AF%91%E5%99%A8%E6%8C%91%E6%88%98%E4%B8%8E%E6%9C%BA%E9%81%8720200821.pptx"
              >AIOT时代深度学习编译器：挑战与机遇20200821</a>

          </p>
          <p class="pad-left">
            报告人：胡春明 北京航空航天大学大学<a
              target="_blank"
              href="https://gitlab.eduxiji.net/nscscc/docs/-/blob/master/%E7%BC%96%E8%AF%91%E5%A4%A7%E8%B5%9B%E6%95%B4%E4%BD%93%E6%83%85%E5%86%B5%E5%88%86%E6%9E%90.pdf"
              >编译大赛整体情况分析</a
            >
          </p>
          <p class="look-del look-title look-top">编译系统设计赛（华为毕昇杯）技术培训（第一场）</p>
          <h4 style=" color: #1fb901;">直播主题(点击主题链接，下载培训报告)：</h4>
           <p class='addLi' style='padding-left:10px;'>
              <ul>
                <li><a href='https://gitlab.eduxiji.net/nscscc/docs/-/blob/master/lectures/%E5%A4%A7%E8%B5%9B%E5%9F%B9%E8%AE%AD%E7%AC%AC%E4%B8%80%E5%9C%BA_%E4%BC%98%E5%8C%96%E7%BC%96%E8%AF%91%E5%99%A8%E6%8A%80%E6%9C%AF.pdf' target="_blank">编译优化技术概览</a></li>
                <li><a href='https://gitlab.eduxiji.net/nscscc/docs/-/blob/master/lectures/%E5%A4%A7%E8%B5%9B%E5%9F%B9%E8%AE%AD%E7%AC%AC%E4%B8%80%E5%9C%BA_AIOT%E6%97%B6%E4%BB%A3%E7%9A%84%E7%BC%96%E7%A8%8B%E8%AF%AD%E8%A8%80_%E7%BC%96%E8%AF%91%E5%99%A8%E4%B8%8E%E6%8C%87%E4%BB%A4%E9%9B%86%E6%9E%B6%E6%9E%84_%E6%9C%BA%E9%81%87_%E6%8C%91%E6%88%98%E4%B8%8E%E6%8A%80%E6%9C%AF%E5%88%86%E4%BA%AB20200517.pdf' target="_blank">AIoT时代的编程语言、编译器与指令集架构</a></li>
                <li><a href='https://gitlab.eduxiji.net/nscscc/docs/-/blob/master/lectures/%E5%A4%A7%E8%B5%9B%E5%9F%B9%E8%AE%AD%E7%AC%AC%E4%B8%80%E5%9C%BA_%E7%BC%96%E8%AF%91%E5%A4%A7%E8%B5%9B%E6%8A%80%E6%9C%AF%E6%96%B9%E6%A1%88%E8%AF%B4%E6%98%8E-V5-20200516.pdf' target="_blank">全国大学生系统能力大赛编译系统设计赛技术方案解读</a></li>
              
              </ul>
            </p>
           <h4 style=" color: #1fb901;">本次直播已经制作成MOOC，访问方式：</h4>
            <p class='addLi' style='padding-left:10px;'>
              <ul>
                <li>进入教学系统：<a href='https://course.educg.net/' target="_blank">course.educg.net</a>, 账号: compiler/compiler 登录</li>
                <li>登录以后，点击在线课堂</li>
        
              </ul>
            </p>
        </div>
      </el-tab-pane>
      <el-tab-pane label="常见问题" name="eight">
        <div class="tabs-tabpane-width addLi">
          <p class="look-del">
              大赛常见问题请访问该wiki：
              <a href=" 
https://gitlab.eduxiji.net/nscscc/docs/-/wikis/home" 
                > 
https://gitlab.eduxiji.net/nscscc/docs/-/wikis/home</a
              >
            </p>
        </div>
      </el-tab-pane>
    </el-tabs>
    <!--2021 操作系统赛     v-if="name == '2021 操作系统赛'"-->
     <el-tabs
      v-model="activeName"
      @tab-click="callback"
      class="common-width"
      v-if="name.indexOf('2021')!=-1&& name.indexOf('操作')!=-1"
  
    >
      <el-tab-pane label="大赛时刻" name="first">
        <div class="tabs-tabpane-width add-img">
             <p class="look-del look-title">
            2021全国大学生计算机系统能力大赛操作系统设计大赛
          </p>
          <p class="pad-index">
            全国大学生计算机系统能力大赛操作系统设计大赛(以下简称“大赛”)是由教育部高等学校计算机类专业教学指导委员会和系统能力培养研究专家组共同发起，以学科竞赛推动专业建设和计算机领域创新人才培养体系改革、培育我国高端芯片及核心系统的技术突破与产业化后备人才为目标,面向高校大学生举办的全国性大赛。
          </p>
          <p class="pad-index">
            大赛旨在选拔未来我国计算机系统相关领域的设计、分析、优化与应用人才,激发学生的想象力、创新力和工程实践能力并培养其团队协作精神,以赛促学、以赛促教,为高质量专业人才搭建交流、展示、合作的开源社区平台，让参与大赛的学生、指导教师、学校和企业都能从中获得补益，助力我国高校与企业在操作系统领域的产学研合作健康快速发展。
          </p>
          <p
            class="look-del"
            style="text-align: center; font-size: 30px; margin-top: 20px"
          >
            全国总决赛获奖结果
          </p>
          <img data-v-8f17cb94="" src="../images/gn_01.png" v-if="name.indexOf('内核')!=-1">
           <img data-v-8f17cb94="" src="../images/nh001.jpg" v-if="name.indexOf('内核')!=-1">
          <img data-v-8f17cb94="" src="../images/gn002.jpg" v-if="name.indexOf('功能')!=-1">
          <img data-v-8f17cb94="" src="../images/gn_03.png">
          <img src="../images/gn_04.png" />
       
          <p
            class="look-del"
            style="text-align: center; font-size: 30px; margin-top: 20px"
          >
            决赛入围名单暨优胜奖获得者
          </p>
           <p class="look-del" style="text-align: center;">【以学校名称拼音排序】</p>
          <p class="look-del" style="text-align: center; margin:10px;" v-if="name.indexOf('内核')!=-1">👇 操作系统内核赛 👇</p>

          
          <img src="../images/gn_05.png" v-if="name.indexOf('内核')!=-1"/>
             <p class="look-del" style="text-align: center;margin:10px;" v-if="name.indexOf('功能')!=-1">👇 操作系统功能赛 👇</p>
          <img src="../images/gn_06.png"  v-if="name.indexOf('功能')!=-1"/>

          <p class="look-del"  style="text-align: center; font-size: 30px; margin-top: 20px">提交入口</p>
             <a-table
            bordered
            :columns="columnsTj"
            :data-source="workTj"
            style="margin-top: 10px"
            :pagination="false"
          >
            <a
              slot="action"
              slot-scope="item, record"
              :href="record.url"
              target="_blank"
              >{{ item }}</a
            >
          </a-table>
          <p class="look-del"  style="text-align: center; font-size: 30px; margin-top: 20px">操作系统大赛报名延长至4月15日</p>
          <p>各高校：</p>
           <p class="pad-index">因疫情影响，高校春季学期开学和返校时间推迟，经全国大学生系统能力大赛组委会研究决定，2021全国大学生系统能力大赛操作系统设计赛报名截止时间延长至 2021 年4月15日。报名条件、报名方式、初赛及决赛时间不变。</p>
       <p class="pad-index">欢迎各高校积极组队参赛！</p>
       <p style="text-align:right">全国大学生系统能力大赛组委会</p>
             <p style="text-align:right">2021年3月30日</p>
              <a
            href="https://course.educg.net/sv2/indexexp/contest/index.jsp"
            target="_blank"
            ><el-button
              type="primary"
              style="width: 112px; margin: 20px auto; display: block"
              >比赛进行中</el-button
            ></a
          >
        </div>
      </el-tab-pane>
      <el-tab-pane label="大赛章程与通知" name="second">
        <div class="tabs-tabpane-width">
          <p class="look-del look-title">
            2021全国大学生计算机系统能力培养大赛操作系统比赛的信息
          </p>
          <a-list
            item-layout="horizontal"
            :data-source="dataTj"
            :split="false"
            class="teshu"
          >
            <a-list-item slot="renderItem" slot-scope="item">
              <a-list-item-meta description="">
                <a slot="title" :href="item.href" target="_blank">
                  <a-icon type="link" /><span style="margin-left: 10px">{{
                    item.title
                  }}</span>
                </a>
              </a-list-item-meta>
            </a-list-item>
          </a-list>
        </div>
      </el-tab-pane>
      <el-tab-pane label="内核要求" name="third"  v-if="name.indexOf('内核')!=-1">
        <div class="tabs-tabpane-width">
          <p class="look-del look-title">内核赛内容与要求</p>
          <p class="pad-left">
             更多操作系统开发实例与文档（不断更新中），请访问：
              <a
                target="_blank"
                href=" 
https://github.com/oscomp/os-competition-info/blob/main/ref-info.md"
                > 
https://github.com/oscomp/os-competition-info/blob/main/ref-info.md</a
              >
            </p>
            <p class="look-del look-title look-top">参赛操作系统期望功能</p>
             <div class="tabs-tabpane-width addLi">
          <ul>
            <li>
              启动和系统初始化
            </li>
            <li>
            内存管理
            </li>
            <li>进程管理和中断异常机制</li>
             <li>系统调用</li>
              <li>文件系统（SimpleFS or Other better FS）</li>
               <li>命令解释程序</li>
         
          </ul>
             <img src="../images/gy01.png" width="80%"/>
                <img src="../images/gy02.png" width="80%"/>
                   <img src="../images/gy03.png" width="80%"/>
                 
        </div>
           
        </div>
      </el-tab-pane>
       <el-tab-pane label="功能赛要求" name="four"  v-if="name.indexOf('功能')!=-1">
        <div class="tabs-tabpane-width">
          <p class="look-del look-title">功能赛内容与要求</p>
          
             <div class="tabs-tabpane-width addLi">
          <ul>
            <li>
              “OS功能设计”赛道的比赛题目： <a href='https://github.com/oscomp' target="_blank">https://github.com/oscomp</a>国内源： <a href='https://gitlab.eduxiji.net/oscomp' target="_blank">https://gitlab.eduxiji.net/oscomp</a>
            </li>
            <li>
            如果对题目有疑问，直接写到对应题目的github repo 的issues中，这样便于分类和保存原始记录。大家对于要选择的题目多比较一下，可以多问问导师和自己学校的老师等，看看是否适合自己。
            </li>
            <li>一个队只能选择一道题。</li>
             <li>报名结束，比赛平台可以开始提交（提交至比赛平台的gitlab，比赛题目内有相应的描述）</li>
           
         
          </ul>
          
        </div>
           
        </div>
      </el-tab-pane>
      <el-tab-pane label="技术报告" name="five">
        <div class="tabs-tabpane-width">
          <div v-for="(item, index) in listAryCz" :key="index">
            <div v-if='!item.detail'>
            <p class="look-del" v-if="item.title">{{ item.title }}</p>
             <p class="look-del" v-if="item.over">{{ item.over }}</p>
            <img :src="item.img" class="tab-imgD" v-if="item.img != ''" />
            <p class="pad-left" v-if="item.time">1. 时间：{{ item.time }}</p>
            <p class="pad-left" v-if="item.href1">
              2. 培训日程与直播地址：
              <a target="_blank" :href="item.href1"> 点击查看</a>
            </p>

            <p class="pad-left" v-if="item.href2">
              3. 直播回放：
              <a target="_blank" :href="item.href2">点击查看回放</a>
            </p>
            <a-table
              bordered
              :columns="item.over?columnschange : columnsBy"
              :data-source="item.dataColumns"
              style="margin-top: 10px"
              :pagination="false"
              v-if="item.dataColumns.length != 0"
            >
              <a
                slot="action"
                slot-scope="text, record"
                target="_blank"
                :href="record.href3"
                v-if="record.href3 != ''"
                >{{ record.hrefName }}</a
              >
            </a-table>
            </div>
            <div v-else>
               <p class="look-del">{{ item.title }}</p>
                <p class="pad-left" style='font-weight:bold'> 更多信息请访问：</p>
               <p class="pad-left">
         <a
              target="_blank"
              href="https://mp.weixin.qq.com/s/UldUUiNyd4hCkuw7vk1sMw"
              > https://mp.weixin.qq.com/s/UldUUiNyd4hCkuw7vk1sMw</a
            >
           
          </p>
            <p class="pad-left" style='font-weight:bold'> 直播时间：</p>
           <p class="pad-left">
          2021年2月10日20：00-21：00
          </p>
            <p class="pad-left" style='font-weight:bold'> 报告简介：</p>
           <p class="pad-index">
          开源软件逐渐成为计算和社会的基石，企业纷纷布局、加入开源，以求通过开源模式发展扩大（开发者和用户）社区，并形成生态。然而，开源生态如何形成、如何持续，尤其是如何吸引和汇聚开源贡献者，是进入开源的企业面临的重要挑战，也是IT产业和社会的挑战。本报告将从国内的开源现状以及面临的挑战这一大背景出发，以典型开源项目为案例，讨论开源社区的参与及汇聚机制，涉及开源许可证的选择、开源社区的形成，以及高校和企业的开源文化养成等问题。
          </p>
           <p class="pad-left" style='font-weight:bold'> 直播回放：</p>
             <div class="pad-left">
         前往观看： <a
              target="_blank"
              href="https://www.bilibili.com/video/BV1eK4y1S7Js"
              > https://www.bilibili.com/video/BV1eK4y1S7Js</a>
              <p>PPT下载： <a
              target="_blank"
              href="https://pan.educg.net/#/s/K5XTa"
              > https://pan.educg.net/#/s/K5XTa</a></p>


          </div>
            </div>
          </div>
           <p class="look-del">2020年ChinaSoft论坛报告：</p>
           <p class='look-index'>点击链接跳转后进入技术报告标签页查看： <a href="https://s4plus.ustc.edu.cn/chinasoft2020ssef/list.htm" target="_blank"> https://s4plus.ustc.edu.cn/chinasoft2020ssef/list.htm
</a></p>
        </div>
      </el-tab-pane>
      <el-tab-pane label="作品开源" name="six">
        <div class="tabs-tabpane-width">
          <p class="look-del look-title">参赛作品开源地址</p>
          <a-table
            bordered
            :columns="columnsK"
            :data-source="workcsNew"
            style="margin-top: 10px"
            :pagination="false"
          >
            <a slot="action" slot-scope="item" :href="item" target="_blank">{{
              item
            }}</a>
          </a-table>
        </div>
      </el-tab-pane>
      <el-tab-pane label="技术支持" name="seven">
        <div class="tabs-tabpane-width">
          <p class="look-del look-title">技术支持</p>
          <p class="pad-left">
           各参赛队请加入官方技术支持QQ群：<a
              target="_blank"
              href="https://jq.qq.com/?_wv=1027&k=T72z2HKg"
              > 541142139</a
            >
            【请注明：学校+姓名】，在群内即时获取技术支持、了解大赛信息。
          </p>
          <p class="look-del look-title">带队教师</p>
          <p class="pad-left">
            准备自己出题训练或者日常教学，请加入QQ群：
            <a
              target="_blank"
              href="https://jq.qq.com/?_wv=1027&k=oLUqvq05"
              >658157307</a
            >
            【仅限 高校教师，请注明：学校+姓名】， <a
              target="_blank"
              href="http://course.educg.net/"
              >申请开课。</a
            >
          </p>
        
        </div>
      </el-tab-pane>
      <el-tab-pane label="常见问题" name="eight">
        <div class="tabs-tabpane-width addLi">
          <p class="look-del look-title">大赛Q&A</p>
          <ul>
            <li>
              <h3>报名截至日期是？截止前可以修改报名信息吗？</h3>
              <p class="pad-left">报名截止日前（4月15日），可以修改报名信息。</p>
            </li>
            <li>
              <h3>组队能否超过三人</h3>
              <p class="pad-left">
                不可以，最多三人。
              </p>
            </li>
              <li>
              <h3>报名表签字处需要谁的签字？</h3>
              <p class="pad-left">
                需要学院主管教学领导签字。如果因为疫情或者其它不可抗力，报名日期截止前无法签名，可以先报名，以后再补，报名表内做个备注。
              </p>
            </li>
              <li>
              <h3>参赛涉及哪些费用？</h3>
              <p class="pad-left">
                1. 大赛不收取任何报名费、参赛费、评审费及技术平台购买费等费用。
              </p>
              <p class="pad-left">
                2. 选手及指导老师在全国总决赛期间产生的交通、住宿、用餐等费用自理。
              </p>
            </li>
              <li>
              <h3>开发板免费提供吗？</h3>
              <p class="pad-left">
                报名结束以后，大赛组委会统一向参赛队免费发放。赛后开发板不收回。
              </p>
            </li>
              <li>
              <h3>自己如何购买开发板？</h3>
              <p class="pad-left">
                <a href='https://item.taobao.com/item.htm?spm=a1z09.2.0.0.3c702e8dMLVWpb&id=591616120470&_u=q3pf8bo634d4' target="_blank">点击此处前往购买。</a>
              </p>
            </li>
              <li>
              <h3>哪些同学可以参赛？</h3>
              <p class="pad-left">
                全国普通高等学校全日制在校生均可参赛。
              </p>
            </li>
              <li>
              <h3>能否使用官方BSP及standalone包中的库函数？</h3>
              <p class="pad-left">
                只要不涉及学术诚信，不是严格禁止的，且可以通过评测机编译的，都可以用。根据组委会的精神，会给大家尽量大的自由度。
              </p>
            </li>
              <li>
              <h3>OS功能设计赛道的出题截止日期是什么时候？</h3>
              <p class="pad-left">
                请各位有出题意向的老师在1月20前完成OS比赛“OS功能设计”题目的提交或更新。
              </p>
            </li>
              <li>
              <h3>OS功能设计赛道的题目什么时候发布？</h3>
              <p class="pad-left">
                组委会将在1月31日确定并对外正式公布“OS功能设计”题目。
              </p>
            </li>
              <li>
              <h3>参赛队指导教师人数限制？</h3>
              <p class="pad-left">
                至少一名指导教师，最多不超过两名指导教师。
              </p>
            </li>
              <li>
              <h3>功能赛是在81道题目里自由选择吗？每个参赛队要求选择几道题？</h3>
              <p class="pad-left">
                一个队伍一道题，可以自由选择。
              </p>
            </li>
                <li>
              <h3>功能设计赛道报名时需要选择题目吗？</h3>
              <p class="pad-left">
                不需要。
              </p>
            </li>
                   <li>
              <h3>MMU是否确实可靠，尤其是多处理器下？</h3>
              <p class="pad-left">
                不保证。需要用户自行测试并take risk。
              </p>
            </li>
                   <li>
              <h3>功能设计赛道和内核实现赛道报名表是一样的吗？</h3>
              <p class="pad-left">
                是一样的。
              </p>
            </li>
                 <li>
              <h3>K210的MMU是符合RISC-V的分页功能的标准？</h3>
              <p class="pad-left">
                现在芯片支持mmu sv39模式（39位虚地址），三级页表；具体的可以看下官方的资料。文档<a href='https://gitlab.educg.net/wangmingjian/os-comp-qa-archive/-/blob/master/riscv-privileged-v1.10.pdf' target="_blank">riscv-privileged-v1.10.pdf</a>中：c) mmu部分，请参考 Sv39: Page-Based 39-bit Virtual-Memory System和相关章节，我们系统用的是39位虚拟地址；也请参考提供的软件代码；
              </p>
            </li>
          </ul>
        <p class="look-del look-title">开发板资料汇总</p>
         <ul>
            <li>
              <h3>由芯片制造商嘉楠提供的文档（芯片型号为勘智k210）：</h3>
              <p class="pad-left"><a href='https://canaan-creative.com/developer' target="_blank"> https://canaan-creative.com/developer</a></p>
            </li>
               <li>
              <h3>由开发板制造商矽速科技提供的文档（开发版型号为Maix-Dock）：</h3>
              <p class="pad-left"><a href='http://cn.dl.sipeed.com/' target="_blank"> http://cn.dl.sipeed.com/</a></p>
            </li>
               <li>
              <h3>risc-v官方文档：</h3>
              <p class="pad-left"><a href='https://riscv.org/technical/specifications/' target="_blank">https://riscv.org/technical/specifications/</a></p>
               <p class="pad-left">注意：K210使用的特权级标准版本为1.9.1。</p>
            </li>
               <li>
              <h3>相关硬件接口文档及教程参考信息：</h3>
              <p class="pad-left"><a href='https://github.com/kendryte/kendryte-doc-datasheet/blob/master/zh-Hans/SUMMARY.md' target="_blank"> https://github.com/kendryte/kendryte-doc-datasheet/blob/master/zh-Hans/SUMMARY.md
</a></p>
                 <p class="pad-left"><a href='https://github.com/oscomp/os-competition-info/blob/main/ref-info.md' target="_blank">https://github.com/oscomp/os-competition-info/blob/main/ref-info.md</a></p>
            </li>
               <li>
              <h3>K210官方SDK中没有支持MMU。如果进行MMU开发可以参考：</h3>
              <p class="pad-left"><a href='https://gitlab.educg.net/wangmingjian/os-comp-qa-archive/-/tree/master/maix%20mmu%20fats' target="_blank">https://gitlab.educg.net/wangmingjian/os-comp-qa-archive/-/tree/master/maix%20mmu%20fats</a></p>
            </li>
         </ul>
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
const columnsBy = [
  { title: "题目", dataIndex: "name", key: "name" },
  { title: "讲者", dataIndex: "age", key: "age" },
  {
    title: "操作",
    dataIndex: "address",
    key: "1",
    scopedSlots: { customRender: "action" },
  },
];
const columnschange = [
   { title: "时间", dataIndex: "time", key: "time" },
  { title: "题目", dataIndex: "name", key: "name" },
  { title: "讲者", dataIndex: "age", key: "age" },
  {
    title: "操作",
    dataIndex: "address",
    key: "1",
    scopedSlots: { customRender: "action" },
  },
];
const dataBy = [
  {
    title: "全国大学生计算机系统能力培养大赛——通知.pdf",
    href: "https://gitlab.eduxiji.net/nscscc/compiler2021/-/blob/master/%E5%85%A8%E5%9B%BD%E5%A4%A7%E5%AD%A6%E7%94%9F%E8%AE%A1%E7%AE%97%E6%9C%BA%E7%B3%BB%E7%BB%9F%E8%83%BD%E5%8A%9B%E5%9F%B9%E5%85%BB%E5%A4%A7%E8%B5%9B__%E7%AB%A0%E7%A8%8B-%E6%9C%80%E7%BB%88.pdf",
  },
  {
    title: "全国大学生计算机系统能力培养大赛——章程.pdf",
    href: "https://gitlab.eduxiji.net/nscscc/compiler2021/-/blob/master/%E5%85%A8%E5%9B%BD%E5%A4%A7%E5%AD%A6%E7%94%9F%E8%AE%A1%E7%AE%97%E6%9C%BA%E7%B3%BB%E7%BB%9F%E8%83%BD%E5%8A%9B%E5%9F%B9%E5%85%BB%E5%A4%A7%E8%B5%9B__%E7%AB%A0%E7%A8%8B-%E6%9C%80%E7%BB%88.pdf",
  },
  {
    title: "2021全国大学生计算机系统能力大赛-编译系统设计赛技术方案.pdf",
    href: "https://gitlab.eduxiji.net/nscscc/compiler2021/-/blob/master/2021%E5%85%A8%E5%9B%BD%E5%A4%A7%E5%AD%A6%E7%94%9F%E8%AE%A1%E7%AE%97%E6%9C%BA%E7%B3%BB%E7%BB%9F%E8%83%BD%E5%8A%9B%E5%A4%A7%E8%B5%9B-%E7%BC%96%E8%AF%91%E7%B3%BB%E7%BB%9F%E8%AE%BE%E8%AE%A1%E8%B5%9B%E6%8A%80%E6%9C%AF%E6%96%B9%E6%A1%88-final.pdf",
  },
];
const dataTj = [
  {
    title: "2021年全国大学生操作系统设计比赛-通知",
    href: "https://pan.educg.net/api/v3/file/get/1256/2021%E5%B9%B4%E5%85%A8%E5%9B%BD%E5%A4%A7%E5%AD%A6%E7%94%9F%E6%93%8D%E4%BD%9C%E7%B3%BB%E7%BB%9F%E8%AE%BE%E8%AE%A1%E6%AF%94%E8%B5%9B-%E9%80%9A%E7%9F%A5.pdf?sign=SOxnH-p_Vv-SIHazUcT35Qr-WmOvVn_rImlvdUlJJpU%3D%3A0",
  },
  {
    title: "2021年全国大学生系统能力大赛操作系统设计赛-章程-最终版",
    href: "https://pan.educg.net/api/v3/file/get/1301/2021%E5%B9%B4%E5%85%A8%E5%9B%BD%E5%A4%A7%E5%AD%A6%E7%94%9F%E7%B3%BB%E7%BB%9F%E8%83%BD%E5%8A%9B%E5%A4%A7%E8%B5%9B%E6%93%8D%E4%BD%9C%E7%B3%BB%E7%BB%9F%E8%AE%BE%E8%AE%A1%E8%B5%9B-%E7%AB%A0%E7%A8%8B-20200127.pdf?sign=8GlzF7I6_z5zTaNcgqjPKMsn3CBgEvtHOpa4IqwAWpc%3D%3A0",
  },
  {
    title: "2021全国大学生计算机系统能力培养大赛操作系统设计赛-技术方案-最新版",
    href: "https://pan.educg.net/api/v3/file/get/1300/2021%E5%85%A8%E5%9B%BD%E5%A4%A7%E5%AD%A6%E7%94%9F%E8%AE%A1%E7%AE%97%E6%9C%BA%E7%B3%BB%E7%BB%9F%E8%83%BD%E5%8A%9B%E5%9F%B9%E5%85%BB%E5%A4%A7%E8%B5%9B%E6%93%8D%E4%BD%9C%E7%B3%BB%E7%BB%9F%E8%AE%BE%E8%AE%A1%E8%B5%9B-%E6%8A%80%E6%9C%AF%E6%96%B9%E6%A1%88-20210127.pdf?sign=w3ADcMdXiIbVrY-5GpYzlKM67ppDzLbEHSMccar4uZY%3D%3A0",
  },
];
const columnsSh = [
  {
    title: "通道连接",
    dataIndex: "name",
    key: "1",
    scopedSlots: { customRender: "action" },
  },
  { title: "开放时间", dataIndex: "time", key: "time" },
];
const workSh = [
  {
    name: `编译系统设计赛-决赛赛后测试通道`,
    url: "https://course.educg.net/acm/contests.jsp",
    time: "2020-08-22 14:55:00 ~ 2020-12-22 00:00:00",
  },
  {
    name: `编译系统设计赛-决赛赛后单用例测试通道`,
    url: "https://course.educg.net/acm/contests.jsp",
    time: "2020-08-22 14:56:00 ~ 2020-12-28 00:00:00",
  },
];
const columnsTj = [
  {
    title: "通道连接",
    dataIndex: "name",
    key: "1",
    scopedSlots: { customRender: "action" },
  },
  { title: "开放时间", dataIndex: "time", key: "time" },
  { title: "报名时间", dataIndex: "timeB", key: "timeB" },
];
const workcsNew = [
{
					topic: '哈尔滨工业大学（深圳）',
					speakers: 'UltraOS',
					url: 'https://gitlab.eduxiji.net/ultrateam/ultraos',
					aisle: 'https://gitlab.eduxiji.net/ultrateam/ultraos'
				}, {
					topic: '华中科技大学',
					speakers: '3Los',
					url: 'https://gitlab.eduxiji.net/retrhelo/xv6-k210',
					aisle: 'https://gitlab.eduxiji.net/retrhelo/xv6-k210'
				}, {
					topic: '中国科学院大学',
					speakers: '404小队',
					url: 'https://gitlab.eduxiji.net/HappyEric/oskernel2021-404',
					aisle: 'https://gitlab.eduxiji.net/HappyEric/oskernel2021-404'
				}, {
					topic: '上海海洋大学',
					speakers: '消失的发际线',
					url: 'https://gitlab.eduxiji.net/calvinxu17/oskernel2021-LinanOS',
					aisle: 'https://gitlab.eduxiji.net/calvinxu17/oskernel2021-LinanOS'
				}, {
					topic: '东北大学',
					speakers: '0x80200000',
					url: 'https://gitlab.eduxiji.net/iPear/syscore',
					aisle: 'https://gitlab.eduxiji.net/iPear/syscore'
				}, {
					topic: '南开大学',
					speakers: '宫老师说的都对',
					url: 'https://gitlab.eduxiji.net/Ruanzhihan/rwos',
					aisle: 'https://gitlab.eduxiji.net/Ruanzhihan/rwos'
				}, {
					topic: '北京大学',
					speakers: '电脑配件队',
					url: 'https://gitlab.eduxiji.net/ckf104/test_commit',
					aisle: 'https://gitlab.eduxiji.net/ckf104/test_commit'
				}, {
					topic: '北京航空航天大学',
					speakers: 'SOS',
					url: 'https://gitlab.eduxiji.net/18373688/sos',
					aisle: 'https://gitlab.eduxiji.net/18373688/sos'
				}, {
					topic: '重庆大学',
					speakers: '广告位招租',
					url: 'https://gitlab.eduxiji.net/deng19992008/openbhos',
					aisle: 'https://gitlab.eduxiji.net/deng19992008/openbhos'
				}, {
					topic: '国防科技大学',
					speakers: '小白闯江湖队',
					url: 'https://gitlab.eduxiji.net/Misure/oskernel2021-x2',
					aisle: 'https://gitlab.eduxiji.net/Misure/oskernel2021-x2'
				}, {
					topic: '北京大学',
					speakers: 'no stay up late',
					url: 'https://gitlab.eduxiji.net/AaronWu/oskernel2021-x',
					aisle: 'https://gitlab.eduxiji.net/AaronWu/oskernel2021-x'
				}, {
					topic: '哈尔滨工业大学（深圳）',
					speakers: '啊普鲁派哒哒哒哒哒"; DROP DATABASE teams;',
					url: 'https://gitlab.eduxiji.net/willson0v0/oscomp_handin',
					aisle: 'https://gitlab.eduxiji.net/willson0v0/oscomp_handin'
				}, {
					topic: '北京航空航天大学',
					speakers: '^^^TOO_LOW^^^',
					url: 'https://gitlab.eduxiji.net/buaawyk2/oskernel2021-too_low',
					aisle: 'https://gitlab.eduxiji.net/buaawyk2/oskernel2021-too_low'
				}, {
					topic: '重庆邮电大学',
					speakers: 'TeamNameNotFound',
					url: 'https://gitlab.eduxiji.net/qq976243638/xos',
					aisle: 'https://gitlab.eduxiji.net/qq976243638/xos'
				}, {
					topic: '重庆师范大学',
					speakers: '小骨头',
					url: 'https://gitlab.eduxiji.net/hzc1998/oskernel2021-xbook2',
					aisle: 'https://gitlab.eduxiji.net/hzc1998/oskernel2021-xbook2'
				}, {
					topic: '西北工业大学',
					speakers: '是谁在呼叫舰队',
					url: 'https://gitlab.eduxiji.net/zhanghr/os',
					aisle: 'https://gitlab.eduxiji.net/zhanghr/os'
				}, {
					topic: '北京工商大学',
					speakers: 'ATOM',
					url: 'https://gitlab.eduxiji.net/chenzhiy/atom',
					aisle: 'https://gitlab.eduxiji.net/chenzhiy/atom'
				}, {
					topic: '中国科学技术大学',
					speakers: 'kCore',
					url: 'https://gitlab.eduxiji.net/kcore2021/kcore',
					aisle: 'https://gitlab.eduxiji.net/kcore2021/kcore'
				}, {
					topic: '青海大学',
					speakers: 'HDACP',
					url: 'https://gitlab.eduxiji.net/chino/chinos',
					aisle: 'https://gitlab.eduxiji.net/chino/chinos'
				}, {
					topic: '北京交通大学',
					speakers: 'pagefault',
					url: 'https://gitlab.eduxiji.net/pagefault/oskernel2021-pagefaultos',
					aisle: 'https://gitlab.eduxiji.net/pagefault/oskernel2021-pagefaultos'
				}, {
					topic: '中国石油大学（华东）',
					speakers: '文火土豆丝',
					url: 'https://gitlab.eduxiji.net/potato-kernal/cy-os',
					aisle: 'https://gitlab.eduxiji.net/potato-kernal/cy-os'
				}, {
					topic: '哈尔滨工业大学（深圳）',
					speakers: 'HoitFS',
					url: 'https://gitlab.eduxiji.net/Deadpool/project325618-47064',
					aisle: 'https://gitlab.eduxiji.net/Deadpool/project325618-47064'
				}, {
					topic: '清华大学',
					speakers: '鲲筋烤',
					url: 'https://gitlab.eduxiji.net/carbon/project325618-89175',
					aisle: 'https://gitlab.eduxiji.net/carbon/project325618-89175'
				}, {
					topic: '哈尔滨工业大学（深圳）',
					speakers: '压缩鸭',
					url: 'https://gitlab.eduxiji.net/apple-ouyang/project325618-87786',
					aisle: 'https://gitlab.eduxiji.net/apple-ouyang/project325618-87786'
				}, {
					topic: '北京航空航天大学',
					speakers: 'panic',
					url: 'https://gitlab.eduxiji.net/mozart/18373821-project325618-35660',
					aisle: 'https://gitlab.eduxiji.net/mozart/18373821-project325618-35660'
				}, {
					topic: '华中科技大学',
					speakers: '无相之风战队',
					url: 'https://gitlab.eduxiji.net/luojia65/project325618-85616',
					aisle: 'https://gitlab.eduxiji.net/luojia65/project325618-85616'
				}, {
					topic: '中山大学',
					speakers: 'Kmon小队',
					url: 'https://gitlab.eduxiji.net/osaware/kytchett-project325618-87880',
					aisle: 'https://gitlab.eduxiji.net/osaware/kytchett-project325618-87880'
				}, {
					topic: '中国科学技术大学',
					speakers: 'KK-OS',
					url: 'https://gitlab.eduxiji.net/Maxwell/project325618-87920',
					aisle: 'https://gitlab.eduxiji.net/Maxwell/project325618-87920'
				}, {
					topic: '聊城大学',
					speakers: '追梦',
					url: 'https://gitlab.eduxiji.net/lizhirui/project325618-83178',
					aisle: 'https://gitlab.eduxiji.net/lizhirui/project325618-83178'
				}, {
					topic: '中山大学',
					speakers: '逸仙OS',
					url: 'https://gitlab.eduxiji.net/NelsonCheung/project325618-87937',
					aisle: 'https://gitlab.eduxiji.net/NelsonCheung/project325618-87937'
				}, {
					topic: '浙江大学',
					speakers: '长城',
					url: 'https://gitlab.eduxiji.net/lx/project325618-88345',
					aisle: 'https://gitlab.eduxiji.net/lx/project325618-88345'
				}, {
					topic: '华中科技大学',
					speakers: '七里香战队',
					url: 'https://gitlab.eduxiji.net/Yzm/project325618-86752',
					aisle: 'https://gitlab.eduxiji.net/Yzm/project325618-86752'
				}, {
					topic: '南开大学',
					speakers: 'NK-伏羲-X',
					url: 'https://gitlab.eduxiji.net/NKU_Yang/project325618-87818',
					aisle: 'https://gitlab.eduxiji.net/NKU_Yang/project325618-87818'
				}, {
					topic: '天津大学',
					speakers: 'Ko.oK',
					url: 'https://github.com/Ko-oK-OS/xv6-rust',
					aisle: 'https://github.com/Ko-oK-OS/xv6-rust'
				}, {
					topic: '合肥工业大学宣城校区',
					speakers: '早睡早起身体好',
					url: 'https://gitlab.eduxiji.net/chenhaitian/project325618-87225',
					aisle: 'https://gitlab.eduxiji.net/chenhaitian/project325618-87225'
				}, {
					topic: '南方科技大学',
					speakers: 'xv6tql',
					url: 'https://gitlab.eduxiji.net/HuaHuaY/project325618-89057',
					aisle: 'https://gitlab.eduxiji.net/HuaHuaY/project325618-89057'
				}, {
					topic: '南京大学',
					speakers: '黄雨晨',
					url: 'https://gitlab.eduxiji.net/Turmiht/project325618-87950',
					aisle: 'https://gitlab.eduxiji.net/Turmiht/project325618-87950'
				}, {
					topic: '济南大学',
					speakers: 'YuLang是最好的语言',
					url: 'https://gitlab.eduxiji.net/wfly1998/project325618-86938',
					aisle: 'https://gitlab.eduxiji.net/wfly1998/project325618-86938'
				}, {
					topic: '中国石油大学（北京）克拉玛依校区',
					speakers: '冲呀，小伙伴！！！',
					url: 'https://gitlab.eduxiji.net/FCH111/project325618-73922',
					aisle: 'https://gitlab.eduxiji.net/FCH111/project325618-73922'
				}, {
					topic: '广州大学',
					speakers: '一定要重拳出击',
					url: 'https://gitlab.eduxiji.net/thf5000y2k/project325618-86900',
					aisle: 'https://gitlab.eduxiji.net/thf5000y2k/project325618-86900'
				}, {
					topic: '北京工商大学',
					speakers: '代码全部都对',
					url: 'https://gitlab.eduxiji.net/btbuljx/project325618-87776',
					aisle: 'https://gitlab.eduxiji.net/btbuljx/project325618-87776'
				}
]

const columns = [
  { title: "时间", dataIndex: "name", key: "name" },
  { title: "培训主题", dataIndex: "age", key: "age" },
  { title: "负责人", dataIndex: "address", key: "1" },
];
const dataColumns = [
  {
    name: `6月4日`,
    age: "编译技术基础",
    address: `张莉（北京航空航天大学）`,
  },
  {
    name: `6月11日`,
    age: "编译框架与CI搭建",
    address: `黄林鹏（上海交通大学）`,
  },
  {
    name: `6月18日`,
    age: "文法与前端设计",
    address: `张昱（中国科学技术大学）`,
  },
  {
    name: `6月25日`,
    age: "中间语言及SSA表示",
    address: `刘先华（北京大学）`,
  },
  {
    name: `7月2日
`,
    age: "中间代码优化",
    address: `冯晓兵（中科院计算所）`,
  },
  {
    name: `7月9日`,
    age: "ARM架构与体系结构相关优化",
    address: `计卫星（北京理工大学）`,
  },
  {
    name: `7月16日`,
    age: "高级编译优化技术",
    address: `翟季冬（清华大学）`,
  },
  {
    name: `7月23日`,
    age: "初赛综合答疑（一）",
    address: `大赛技术组`,
  },
  {
    name: `7月30日`,
    age: "初赛综合答疑（二）",
    address: `大赛技术组`,
  },
];
const columnsK = [
  { title: "学校", dataIndex: "topic", key: "topic" },
  { title: "队伍名称", dataIndex: "speakers", key: "speakers" },
  {
    title: "源码仓库地址",
    dataIndex: "url",
    key: "1",
    scopedSlots: { customRender: "action" },
  },
];
const worklist = [
  {
    topic: "清华大学",
    speakers: "小林家的编译器 ",
    url: "https://github.com/kobayashi-compiler/kobayashi-compiler",
    aisle: "https://github.com/kobayashi-compiler/kobayashi-compiler",
  },
  {
    topic: "北京航空航天大学",
    speakers: "No Segmentation Fault Work",
    url: "https://github.com/No-SF-Work/ayame",
    aisle: "https://github.com/No-SF-Work/ayame",
  },
  {
    topic: "湖南大学",
    speakers: "沙梨酱耶",
    url: "https://github.com/abrasumente233/SallyCompiler",
    aisle: "https://github.com/abrasumente233/SallyCompiler",
  },
  {
    topic: "华南理工大学",
    speakers: "TINBAC Is Not Building A Compiler",
    url: "https://gitlab.eduxiji.net/tinbac/tinbaccc.git perf-gvnfix",
    aisle: "Public",
  },
  {
    topic: "西北工业大学",
    speakers: "胡编乱造不队",
    url: "https://github.com/luooofan/hblzbd_compiler",
    aisle: "https://github.com/luooofan/hblzbd_compiler",
  },
  {
    topic: "北京航空航天大学",
    speakers: "真实匿名队",
    url: "https://gitlab.eduxiji.net/csc2021-buaa-reallyanonymous/compiler.git master",
    aisle: "Public",
  },
  {
    topic: "北京大学",
    speakers: "全场景分布式优化队",
    url: "https://gitlab.eduxiji.net/PanJason/harmony_comp.git enable_gzz",
    aisle: "Public",
  },
  {
    topic: "中国科学技术大学",
    speakers: "Maho_shojo",
    url: "https://github.com/wildoranges/MahoShojo",
    aisle: "https://github.com/wildoranges/MahoShojo",
  },
  {
    topic: "南开大学",
    speakers: "天津泰达",
    url: "https://github.com/Young-Cody/CSC2021-NKU-TEDA",
    aisle: "https://github.com/Young-Cody/CSC2021-NKU-TEDA",
  },
  {
    topic: "华中科技大学",
    speakers: "六角亭华工队",
    url: "https://gitlab.eduxiji.net/3usi9/plain_syc",
    aisle: "Public",
  },
  {
    topic: "中国科学院大学",
    speakers: "ucasCC",
    url: "https://gitlab.eduxiji.net/csc2021-ucas-ucascc/compiler.git 8_14_1",
    aisle: "Public",
  },
  {
    topic: "哈尔滨工业大学（深圳）",
    speakers: "xwh说的都对",
    url: "https://gitlab.eduxiji.net/whsu/compile-a zhj",
    aisle: "Public",
  },
  {
    topic: "北京科技大学",
    speakers: "你这编译器保熟吗",
    url: "https://github.com/ustb-owl/Lava",
    aisle: "https://github.com/ustb-owl/Lava",
  },
  {
    topic: "西北工业大学",
    speakers: "Calcifer",
    url: "https://github.com/n13eho/CalciferCompiler",
    aisle: "https://github.com/n13eho/CalciferCompiler",
  },
  {
    topic: "中国科学技术大学",
    speakers: "擅长捉弄的编译器",
    url: "https://github.com/misakihanayo/bazinga_compiler",
    aisle: "https://github.com/misakihanayo/bazinga_compiler",
  },
  {
    topic: "杭州电子科技大学",
    speakers: "芜湖起飞",
    url: "https://gitlab.eduxiji.net/sys_compiler/syscompiler.git yztz",
    aisle: "Public",
  },
  {
    topic: "北京大学",
    speakers: "编译原理不汇编",
    url: "https://gitlab.eduxiji.net/pku1800013122/finals",
    aisle: "https://gitlab.eduxiji.net/pku1800013122/finals",
  },
  {
    topic: "北京科技大学",
    speakers: "快马加鞭",
    url: "https://gitlab.eduxiji.net/USTB_NO1/compiler.git --branch=tmp1",
    aisle: "Public",
  },
  {
    topic: "南开大学",
    speakers: "super.calculate",
    url: "https://gitlab.eduxiji.net/csc2020-nankai-super.calculate/compiler",
    aisle: "Public",
  },
  {
    topic: "北京航空航天大学",
    speakers: "早安！白给人",
    url: "https://github.com/Forever518/Whitee",
    aisle: "https://github.com/Forever518/Whitee",
  },
  {
    topic: "华中科技大学",
    speakers: "DragonAC",
    url: "https://github.com/showstarpro/sysy",
    aisle: "https://github.com/showstarpro/sysy",
  },
  {
    topic: "华中科技大学",
    speakers: "Spica",
    url: "https://gitlab.eduxiji.net/hust-spica/kisyshot",
    aisle: "Public",
  },
];
const workByt = [
  {
    topic: "清华大学",
    speakers: "编程是一件很危险的事 ",
    url: "https://github.com/TrivialCompiler/TrivialCompiler",
    aisle: "https://github.com/TrivialCompiler/TrivialCompiler",
  },
  {
    topic: "北京科技大学",
    speakers: "DR直呼内行",
    url: "https://github.com/MaxXSoft/MimiC",
    aisle: "https://github.com/MaxXSoft/MimiC",
  },
  {
    topic: "哈尔滨工业大学（深圳）",
    speakers: "形式语言与复读机",
    url: "https://github.com/nzh63/syc",
    aisle: "https://github.com/nzh63/syc",
  },
  {
    topic: "北京航空航天大学",
    speakers: "段地址不队",
    url: "https://github.com/segviol/indigo",
    aisle: "https://github.com/segviol/indigo",
  },
  {
    topic: "北京交通大学",
    speakers: "斯缤楷忒（SpinCat）",
    url: "https://gitlab.eduxiji.net/CSC2020-BJTU-SpinCat/compiler",
    aisle: "https://gitlab.eduxiji.net/CSC2020-BJTU-SpinCat/compiler",
  },
  {
    topic: "东北大学",
    speakers: "Q29tcGlsZXI=",
    url: "https://github.com/i-Pear/CSC2020-NEU-Q29tcGlsZXI-",
    aisle: "https://github.com/i-Pear/CSC2020-NEU-Q29tcGlsZXI-",
  },
  {
    topic: "电子科技大学（清水河）",
    speakers: "创造1010",
    url: "https://github.com/togetherwhenyouwant/Creator_1010_Compiler.git",
    aisle: "https://github.com/togetherwhenyouwant/Creator_1010_Compiler.git",
  },
  {
    topic: "中国科学技术大学",
    speakers: "燃烧我的编译器",
    url: "https://github.com/mlzeng/CSC2020-USTC-FlammingMyCompiler",
    aisle: "https://github.com/mlzeng/CSC2020-USTC-FlammingMyCompiler",
  },
  {
    topic: "成都理工大学",
    speakers: "oops sysy",
    url: "https://gitlab.eduxiji.net/dvs/compiler",
    aisle: "https://gitlab.eduxiji.net/dvs/compiler",
  },
];

export default {
  data() {
    return {
      activeName: "first",
      columns,
      dataBy,
      columnsK,
      workByt,
      worklist,
      dataColumns,
      columnsBy,
      columnsSh,
      workSh,
      listAry: [],
      listAryCz:[],
      workTj: [],
      columnsTj,
      dataTj,
      columnschange,
      workcsNew
    };
  },
  props: {
    name: String,
  },
  mounted() {
    debugger;
    if (this.name.indexOf("功能") != -1) {
      this.workTj = [
        {
          name: `OS功能设计`,
          url: "https://course.educg.net/sv2/indexexp/contest/contest.jsp?contestID=97PWo20nQDw",
          time: "2021-01-01 ~ 2021-08-22",
          timeB: "2021-01-01 ~ 2021-04-15",
        },
      ];
    } else {
      this.workTj = [
        {
          name: `OS内核设计`,
          url: "https://course.educg.net/sv2/indexexp/contest/contest.jsp?contestID=DP3AzDO3AFM",
          time: "2021-01-01 ~ 2021-08-22",
          timeB: "2021-01-01 ~ 2021-04-15",
        },
      ];
    }
    this.listAry = [
      {
        title: "2021编译大赛（华为毕昇杯）第二次答疑会议录像",
        time: "2021年8月1日",
        img: "",
        href1: "",
        href2: "https://www.bilibili.com/video/BV1ub4y1r7yt/",
        dataColumns: [
          {
            name: `关于隐藏测试用例的提示`,
            age: "",
            href3:
              "https://pan.educg.net/#/s/leAC9/pdf?name=%E5%85%B3%E4%BA%8E%E9%9A%90%E8%97%8F%E6%B5%8B%E8%AF%95%E7%94%A8%E4%BE%8B%E7%9A%84%E6%8F%90%E7%A4%BA.pdf",
            hrefName: "查看",
          },
        ],
      },
      {
        title: "2021编译大赛（华为毕昇杯）第一次答疑会议录像",
        time: "2021年7月30日",
        href1: "",
        img: "",
        href2: "https://www.bilibili.com/video/BV1ub4y1r7yt/",
        dataColumns: [],
      },
      {
        title: "2021编译大赛（华为毕昇杯）第四次技术培训直播",
        time: "2021年5月8日 14:00-17:30",
        img: require("../images/5.8.3ccaaccd.jpg"),
        href2: "https://mp.weixin.qq.com/s/TT4i1NXhEoSsB-g20L7pHg",
        dataColumns: [
          {
            name: `“编译”杂谈`,
            age: "冯晓兵",
            href3: "",
            hrefName: "下载",
          },
          {
            name: `编译器中间表示的形式、特点及发展趋势`,
            age: "计卫星",
            href3: "",
            hrefName: "下载",
          },
          {
            name: `毕昇编译器中的自动向量化调优`,
            age: "魏伟",
            href3: "",
            hrefName: "下载",
          },
          {
            name: `面向能力培养的编译课程改革实践：经验与反思`,
            age: "林奕",
            href3: "",
            hrefName: "下载",
          },
          {
            name: `元编译：另一种视角下的 IR 设计与实现`,
            age: "朱泽衢",
            href3: "",
            hrefName: "下载",
          },
        ],
      },
      {
        title: "2021编译系统设计赛（华为毕昇杯）第三次技术培训",
        time: "2021年5月1日 14:00-17:00",
        img: require("../images/5.1.8813d9d7.jpg"),
        href1: "",
        href2:
          "https://mp.weixin.qq.com/s?__biz=MzI2MjI1NDMxOQ==&mid=2247487506&idx=2&sn=1c611f7da2034d4959174e7a02ea22d6&chksm=ea4cab92dd3b2284c0494ae725e5dbe3b31f05b4d23dc285f9698926966c4c79e623260264f9&mpshare=1&scene=23&srcid=0429K2iMcM0zuvjLF45A80tQ&sharer_sharetime=1619699061393&sharer_shareid=2c0ae9808d2534407285eadc94e70e59#rd",
        dataColumns: [
          {
            name: `面向高性能计算的编译技术：回顾与展望`,
            age: "方建滨",
            href3: "",
            hrefName: "下载",
          },
          {
            name: `编译经典算法——寄存器分配的设计经验分享`,
            age: "郑智文",
            href3: "",
            hrefName: "下载",
          },
          {
            name: `教学与竞赛相长，提升人才培养质量`,
            age: "沈俊",
            href3: "",
            hrefName: "下载",
          },
          {
            name: `SysY Compiler 项目经验分享`,
            age: "陈清源",
            href3: "",
            hrefName: "下载",
          },
        ],
      },
      {
        title: "2021编译系统设计赛（华为毕昇杯）第二次技术培训",
        time: "21年4月24日 14:00-17:30",
        img: require("../images/4.24.03aa04bd.jpg"),
        href1: "https://mp.weixin.qq.com/s/gbVgZ5cvNNRFj7q7jeCPrQ",
        href2: "https://www.bilibili.com/video/BV1Lp4y147Xy/",
        dataColumns: [
          {
            name: `编译器测试研究进展`,
            age: "江贺",
            href3:
              "https://pan.educg.net/api/v3/file/get/3881/%E7%BC%96%E8%AF%91%E5%99%A8%E6%B5%8B%E8%AF%95%E7%A0%94%E7%A9%B6%E8%BF%9B%E5%B1%95.pdf?sign=NabPHNJqLXUj7NRRCFgqWO32OLPmA9GYR6BFGzEdP7Q%3D%3A0",
            hrefName: "查看",
          },
          {
            name: `编译经典算法—指令调度的设计经验分享`,
            age: "郑智文",
            href3: "https://pan.educg.net/#/s/51MSe",
            hrefName: "下载",
          },
          {
            name: `《编译原理》课程和编译大赛的一些思考和讨论`,
            age: "史晓华",
            href3: "https://pan.educg.net/#/s/gPWHL",
            hrefName: "下载",
          },
          {
            name: `TrivialCompiler经验分享`,
            age: "陈晟祺",
            href3:
              "https://pan.educg.net/api/v3/file/get/3876/%E7%BB%8F%E9%AA%8C%E5%88%86%E4%BA%AB_20210424.pdf?sign=AnkamCnCSafV9oaW9QyEdApz3Jriue8oJO-tBL3F-0c%3D%3A0",
            hrefName: "查看",
          },
        ],
      },
      {
        title: "2021编译系统设计赛（华为毕昇杯）第一次技术培训",
        time: "2021年4月17日 9:30-12:00",
        href1: "https://mp.weixin.qq.com/s/LL1csj_xiU8zzDpfzxAkQQ",
        img: require("../images/4.17.d2bc5ff4.jpg"),
        href2: "https://www.bilibili.com/video/BV1gf4y1p7kR/",
        dataColumns: [
          {
            name: `面向人工智能芯片的张量编译器`,
            age: "翟季冬",
            href3:
              "https://pan.educg.net/api/v3/file/get/3842/%E4%BA%BA%E5%B7%A5%E6%99%BA%E8%83%BD%E8%8A%AF%E7%89%87%E7%BC%96%E8%AF%91%E5%99%A8.pdf?sign=bkvkLaEvESvDkb8ISrt6q2Jifjl-GB__6__32LRDJeI%3D%3A0",
            hrefName: "查看",
          },
          {
            name: `毕昇编译器的设计之路`,
            age: "魏伟",
            href3:
              "https://pan.educg.net/api/v3/file/get/3872/%E6%AF%95%E6%98%87%E7%BC%96%E8%AF%91%E5%99%A8%E8%AE%BE%E8%AE%A1%E4%B8%8E%E4%BC%98%E5%8C%96%E6%8A%80%E6%9C%AF.pdf?sign=WZxhPlx6eV88CPbv5iLNCVt7sdMTA6hYmtaQ6xb0iJY%3D%3A0",
            hrefName: "查看",
          },
          {
            name: `以系统和创新能力培养为导向的编译实践`,
            age: "李诚",
            href3:
              "https://gitlab.eduxiji.net/nscscc/compiler2021/-/blob/master/2021%E7%BC%96%E8%AF%91%E7%B3%BB%E7%BB%9F%E5%A4%A7%E8%B5%9B%E7%AC%AC%E4%B8%80%E6%AC%A1%E6%8A%80%E6%9C%AF%E5%9F%B9%E8%AE%AD%E8%B5%84%E6%96%99/%E7%BC%96%E8%AF%91%E6%AF%94%E8%B5%9B-%E5%9F%B9%E8%AE%AD-%E4%B8%AD%E7%A7%91%E5%A4%A7-%E6%9D%8E%E8%AF%9A.pdf",
            hrefName: "查看",
          },
          {
            name: `编译大赛经验谈`,
            age: "邢其正",
            href3:
              "https://gitlab.eduxiji.net/nscscc/compiler2021/-/blob/master/2021%E7%BC%96%E8%AF%91%E7%B3%BB%E7%BB%9F%E5%A4%A7%E8%B5%9B%E7%AC%AC%E4%B8%80%E6%AC%A1%E6%8A%80%E6%9C%AF%E5%9F%B9%E8%AE%AD%E8%B5%84%E6%96%99/%E7%BC%96%E8%AF%91%E5%A4%A7%E8%B5%9B%E7%BB%8F%E9%AA%8C%E8%B0%88.pdf",
            hrefName: "查看",
          },
        ],
      },
    ];
      this.listAryCz = [
        {
        title: "2021年07月25日2021操作系统大赛决赛阶段第一次技术报告和交流",
        time: "2021年07月25日14:00-17:30",
        img: "",
        href1: "",
        href2: "https://www.bilibili.com/video/BV15w411R7pJ/",
        dataColumns: [
          {
            name: `决赛第一阶段开发历程分享`,
            age: "王辉宇",
            href3:
              "https://pan.educg.net/#/s/xJbHk/doc?name=%E5%86%B3%E8%B5%9B%E7%AC%AC%E4%B8%80%E9%98%B6%E6%AE%B5-%E9%87%8D%E5%BA%86%E7%90%86%E5%B7%A5%E5%A4%A7%E5%AD%A6-%E7%8E%8B%E8%BE%89%E5%AE%87.pptx",
            hrefName: "查看",
          },
           {
            name: `LinanOS决赛第一阶段开发历程分享`,
            age: "许春林",
            href3:
              "https://pan.educg.net/#/s/JBYiX/doc?name=%E4%B8%8A%E6%B5%B7%E6%B5%B7%E6%B4%8B%E5%A4%A7%E5%AD%A6-%E8%AE%B8%E6%98%A5%E6%9E%97.pptx",
            hrefName: "查看",
          },
             {
            name: `xv6-k210简单指南`,
            age: "陆思彤、刘一鸣",
            href3:
              "https://pan.educg.net/#/s/zM3sO/doc?name=%E6%8A%A5%E5%91%8A_%E5%8D%8E%E4%B8%AD%E7%A7%91%E6%8A%80%E5%A4%A7%E5%AD%A6_%E9%99%86%E6%80%9D%E5%BD%A4_%E5%88%98%E4%B8%80%E9%B8%A3.pptx",
            hrefName: "查看",
          },
             {
            name: `操作系统竞赛决赛阶段一分享`,
            age: "王宇翔",
            href3:
              "https://pan.educg.net/#/s/W9ru5/doc?name=%E5%86%B3%E8%B5%9B%E5%88%86%E4%BA%AB-%E5%8C%97%E4%BA%AC%E8%88%AA%E7%A9%BA%E8%88%AA%E5%A4%A9%E5%A4%A7%E5%AD%A6-%E7%8E%8B%E5%AE%87%E7%BF%94.pptx",
            hrefName: "查看",
          },

        ],
      },
       {
        title: "2021操作系统大赛初赛阶段第七次技术培训",
        time: "2021年5月23日14:00-17:30",
        img: "",
        href1: "https://mp.weixin.qq.com/s?__biz=MzI2MjI1NDMxOQ==&mid=2247487867&idx=1&sn=bd4d0101ee204c094f8f48346f707662&chksm=ea4caafbdd3b23ede8a227d44f4b795538c761ca8d2e6b01edf9e416e677539ad95c071fadf1&mpshare=1&scene=23&srcid=0528MCwnSAxde6tonUmsU9hv&sharer_sharetime=1622163347533&sharer_shareid=2c0ae9808d2534407285eadc94e70e59#rd",
        href2: "https://www.bilibili.com/video/BV13V41147C5/",
        dataColumns: [
          {
            name: `WASM基础架构安全漏洞及基于软件的故障隔离`,
            age: "李隆",
            href3:
              "",
            hrefName: "查看",
          },
           {
            name: `StartOS开发历程分享（指导教师：杨宏雨`,
            age: "王辉宇",
            href3:
              "",
            hrefName: "查看",
          },
           {
            name: `基于xv6-k210项目参加并完成内核实现赛初赛（指导教师：文艳军）`,
            age: "卢昶诚",
            href3:
              "",
            hrefName: "查看",
          },
        
           

        ],
      },
       {
        title: "2021操作系统大赛初赛阶段第六次技术培训",
        time: "2021年5月23日14:00-17:30",
        img: "",
        href1: "https://mp.weixin.qq.com/s/WJCzqQaH6a_ZD4xbDjkgRw",
        href2: "https://www.bilibili.com/video/BV1LU4y1L7Q8/",
        dataColumns: [
          {
            name: `cgroup并发创建优化`,
            age: "陶义",
            href3:
              "",
            hrefName: "查看",
          },
           {
            name: `搭建面向教学的嵌入式RISC-V计算机系统`,
            age: "徐金焱",
            href3:
              "",
            hrefName: "查看",
          },
           {
            name: `云原生化与安全容器的前世今生`,
            age: "王旭",
            href3:
              "",
            hrefName: "查看",
          },
         
           

        ],
      },
       {
        title: "2021操作系统大赛初赛阶段第五次技术培训",
        time: "2021年5月16日14:00-17：30",
        img: "",
        href1: "",
        href2: "https://www.bilibili.com/video/BV1154y1V7uE/",
        dataColumns: [
          {
            name: `内核与驱动模糊测试技术介绍`,
            age: "张超",
            href3:
              "",
            hrefName: "查看",
          },
           {
            name: `麒麟移动应用环境`,
            age: "黄晟",
            href3:
              "",
            hrefName: "查看",
          },
           {
            name: `中科创达在AIoT OS领域的实践与大赛赛题分析`,
            age: "邓凡平",
            href3:
              "",
            hrefName: "查看",
          },
         

        ],
      },
       {
        title: "2021操作系统大赛初赛阶段第四次技术培训",
        time: "2021年5月9日14:00-18:00",
        img: "",
        href1: "https://mp.weixin.qq.com/s?__biz=MzI2MjI1NDMxOQ==&mid=2247487742&idx=2&sn=3a1bd1870396ec25fba94b8385df517f&chksm=ea4cab7edd3b226885e7c879cdab80954692fdc7648887812c3c92afe0a6f8d785a74baf67d7&mpshare=1&scene=23&srcid=0508fMDXAk8RkG1cNGriLS4R&sharer_sharetime=1620443628124&sharer_shareid=2c0ae9808d2534407285eadc94e70e59#rd",
        href2: "https://www.bilibili.com/video/BV1EU4y1L7Uv/",
        dataColumns: [
          {
            name: `操作系统正确性及其验证技术`,
            age: "赵永望",
            href3:
              "",
            hrefName: "查看",
          },
           {
            name: `以risc-v ucore为例——链接、烧录、启动介绍`,
            age: "彭天祥",
            href3:
              "",
            hrefName: "查看",
          },
           {
            name: `rCore-Tutorial v3.5在k210上的运行演示及开发指南`,
            age: "吴一凡",
            href3:
              "",
            hrefName: "查看",
          },
           {
            name: `RT-Thread Smart到K210上的移植`,
            age: "李志锐",
            href3:
              "",
            hrefName: "查看",
          },
            {
            name: `xv6-k210开发101`,
            age: "刘一鸣、陆思彤",
            href3:
              "",
            hrefName: "查看",
          },

        ],
      }, {
        title: "2021操作系统大赛初赛阶段第三次技术培训",
        time: "2021年5月2日14:30-17:30",
        img: "",
        href1: "https://live.eyunbo.cn/live/61645?uin=1729",
        href2: "https://www.bilibili.com/video/BV1nv411V75B/",
        dataColumns: [
          {
            name: `https://www.bilibili.com/video/BV1nv411V75B/`,
            age: "戚正伟",
            href3:
              "",
            hrefName: "查看",
          },
           {
            name: `Linux故障分析实践及自研操作系统难点探讨`,
            age: "谢宝友",
            href3:
              "",
            hrefName: "查看",
          },
           {
            name: `系统调用的分析以及在xbook2内核中的实现`,
            age: "胡自成",
            href3:
              "",
            hrefName: "查看",
          },
          
           

        ],
      },
       {
        title: "2021操作系统大赛初赛阶段第二次技术培训",
        time: "2021年4月25日18:30-22:00",
        img: "",
        href1: "https://mp.weixin.qq.com/s?__biz=MzI2MjI1NDMxOQ==&mid=2247487337&idx=1&sn=6d8ef3de6fc4bb1b6c50ca14ed7e7023&chksm=ea4cb4e9dd3b3dffb945bc4fc7e19e4393fa482112adbd022f1536777679a9f5f235151640a7&mpshare=1&scene=23&srcid=0423zGiY4UdgUBEfClyb5IQw&sharer_sharetime=1619164847335&sharer_shareid=2c0ae9808d2534407285eadc94e70e59#rd",
        href2: "https://www.bilibili.com/video/BV1H5411g7o2",
        dataColumns: [
          {
            name: `Linux内核调度器的设计与实战`,
            age: "宋宝华",
            href3:
              "https://pan.educg.net/api/v3/file/get/3877/OS%E7%AB%9E%E8%B5%9B_scheduler.pdf?sign=GP25jINEtK372i3YiKyCtBXSGkzqWrQuUOzhOZRKrbM%3D%3A0",
            hrefName: "查看",
          },
           {
            name: `risc-v ucore OS与SD卡驱动在K210上的移植`,
            age: "彭天祥",
            href3:
              "https://pan.educg.net/#/s/GdViZ",
            hrefName: "下载",
          },
           {
            name: `OS Tutorial实验与K210经验分享`,
            age: "张译仁、吴一凡",
            href3:
              "https://pan.educg.net/api/v3/file/get/3880/OS%20Tutorial%E4%B8%8EK210%E7%BB%8F%E9%AA%8C%E5%88%86%E4%BA%AB.pdf?sign=wB4dv6QUtNnyq_KrTUhzD_Nz8WiizuPAkjAF1D_9Wxo%3D%3A0",
            hrefName: "查看",
          },
           {
            name: `zCore内核设计和对RISCV架构的支持`,
            age: "王润基、萧络元",
            href3:
              "https://pan.educg.net/api/v3/file/get/3879/zCore%E5%86%85%E6%A0%B8%E8%AE%BE%E8%AE%A1%E5%92%8C%E5%AF%B9RISCV%E6%9E%B6%E6%9E%84%E7%9A%84%E6%94%AF%E6%8C%81%28%E5%90%88%29.pdf?sign=gZ-A_0voi-kaN3RrvvTlLUbAhcsFPLCn6J7RFIwjffs%3D%3A0",
            hrefName: "查看",
          },
           

        ],
      },
       {
        title: "4月18日操作系统大赛初赛阶段第一次技术培训",
        time: "2021年4月18日14:00-18：00",
        img: "",
        href1: "https://mp.weixin.qq.com/s/uheFUa4ypS9-6mfOnCXdFQ",
        href2: "https://www.bilibili.com/video/BV1kB4y1A7vk",
        dataColumns: [
          {
            name: `操作系统崩溃问题追踪及性能问题追踪`,
            age: "国科环宇 周洲仪",
            href3:
              "https://pan.educg.net/#/s/pBQCy",
            hrefName: "下载",
          },
           {
            name: `xv6 on k210 架构设计与FAT32移植`,
            age: "华中科大 陆思彤、刘一鸣",
            href3:
              "https://pan.educg.net/api/v3/file/get/3850/%E4%B8%80%E7%A7%8D%E5%85%B1%E4%BA%AB%E8%B0%83%E5%BA%A6%E5%99%A8%E7%9A%84%E5%BC%82%E6%AD%A5%E5%86%85%E6%A0%B8%E8%AE%BE%E8%AE%A1.pdf?sign=yu0E7TfIxyx0OKl7nnyl0K6HcuNqA121NcCcp_LA9V4%3D%3A0",
            hrefName: "查看",
          },
           {
            name: `一种共享调度器的协程内核设计`,
            age: "华中科大 蒋周奇（洛佳）",
            href3:
              "https://pan.educg.net/api/v3/file/get/3851/xv6-k210%E7%AE%80%E5%8D%95%E6%8C%87%E5%8D%97.pdf?sign=pJejma9ILmFlYszY4_sTbzzc9V2sONCegLClEBuY4qc%3D%3A0",
            hrefName: "查看",
          },
      

        ],
      }, {
        title: "2021年4月11日操作系统大赛技术培训",
        time: "2021年4月11日14:00-18:00",
        img: "",
        href1: "https://mp.weixin.qq.com/s/hl_3N6zUGbgIyCTu5jQhaQ",
        href2: "https://www.bilibili.com/video/BV11K4y1o7sQ",
        dataColumns: [
          {
            name: `“开源软件供应链点亮计划”背后的故事`,
            age: "中科院软件所 武延军",
            href3:
              "https://pan.educg.net/api/v3/file/get/3853/20210411-%E5%BC%80%E6%BA%90%E8%BD%AF%E4%BB%B6%E4%BE%9B%E5%BA%94%E9%93%BE%E7%82%B9%E4%BA%AE%E8%AE%A1%E5%88%92%E8%83%8C%E5%90%8E%E7%9A%84%E6%95%85%E4%BA%8B.pdf?sign=RSdQq42ixddhsKXtH8LV7M-45uWHq6sdso0maieQYn4%3D%3A0",
            hrefName: "查看",
          },
           {
            name: `OS新世界`,
            age: "阿里云 王贇",
            href3:
              "https://pan.educg.net/api/v3/file/get/3852/20210411-OS%E6%96%B0%E4%B8%96%E7%95%8C.pdf?sign=X_CftbsiBrUqZNyfzNThdtrnqc5pHno9j8BF0phQhVo%3D%3A0",
            hrefName: "查看",
          },
           {
            name: `通用操作系统的现状和发展趋势`,
            age: "统信软件 王耀华",
            href3:
              "https://pan.educg.net/#/s/bWNI3",
            hrefName: "下载",
          },
           {
            name: `SyberOS介绍及大赛命题思路`,
            age: "元心 章希",
            href3:
              "https://pan.educg.net/#/s/KdXca",
            hrefName: "下载",
          },
           

        ],
      },
       {
        title: "2021年4月4日操作系统大赛技术培训",
        time: "2021年4月4日14:00-18:00",
        img: "",
        href1: "https://mp.weixin.qq.com/s/FjsP0cU3r-MSdZZ6wz2Xyw",
        href2: "https://www.bilibili.com/video/BV1SK4y1o7Pv",
        dataColumns: [
          {
            name: `软硬件协同神经网络异构加速器`,
            age: "清华大学电子工程系 汪玉",
            href3:
              "https://pan.educg.net/#/s/xWzhk",
            hrefName: "下载",
          },
           {
            name: `龙芯指令集介绍`,
            age: "中科院计算所 张福新",
            href3:
              "https://pan.educg.net/api/v3/file/get/3858/20210404-%E5%BC%A0%E7%A6%8F%E6%96%B0-loongarch.pdf?sign=o-I4vKLPH-WqqXD8AmGkEK3PraV3kdOkAgXbZPL7t4A%3D%3A0",
            hrefName: "查看",
          },
           {
            name: `从产品的角度看处理器架构发展`,
            age: "全志科技 刘邵华",
            href3:
              "https://pan.educg.net/api/v3/file/get/3856/20210404-%E5%88%98%E9%82%B5%E5%8D%8E-%E4%BB%8E%E4%BA%A7%E5%93%81%E7%9A%84%E8%A7%92%E5%BA%A6%E7%9C%8B%E5%A4%84%E7%90%86%E5%99%A8%E6%9E%B6%E6%9E%84%E5%8F%91%E5%B1%95.pdf?sign=uYJmijZsEJTkJvNs-BKWjiyMaqHt6BCSr84BHaG9WLg%3D%3A0",
            hrefName: "查看",
          },
           {
            name: `边缘AI芯片：加速计算架构变革新10年`,
            age: "嘉楠科技 孙刚",
            href3:
              "https://pan.educg.net/#/s/VPlCq",
            hrefName: "下载",
          },
           

        ],
      },
       {
        title: "2021年全国大学生计算机系统能力大赛 操作系统设计赛技术培训（第四场）",
        time: "2021年3月28日14:00-18:00",
        img: "",
        href1: "https://mp.weixin.qq.com/s/n30NJ1pgnfYRmTMKh137JQ",
        href2: "https://www.bilibili.com/video/BV1s84y1F7U2",
        dataColumns: [
          {
            name: `应鼓励从头构建先进的基础软件`,
            age: "陈文光",
            href3:
              "https://pan.educg.net/api/v3/file/get/3865/%E5%BA%94%E9%BC%93%E5%8A%B1%E4%BB%8E%E5%A4%B4%E6%9E%84%E5%BB%BA%E5%85%88%E8%BF%9B%E7%9A%84%E7%B3%BB%E7%BB%9F%E8%BD%AF%E4%BB%B6.pdf?sign=peBEqj3BW0_X3GQoIX9fi51vBPzbWfE2s7I_y82vSrs%3D%3A0",
            hrefName: "查看",
          },
           {
            name: `基于麒麟操作系统实现内核数据竞态探测器`,
            age: "刘正元",
            href3:
              "https://pan.educg.net/api/v3/file/get/3866/KCSAN-Linux%E5%86%85%E6%A0%B8%E6%95%B0%E6%8D%AE%E7%AB%9E%E6%80%81%E6%8E%A2%E6%B5%8B%E5%99%A8%282%29-%E5%88%98%E6%AD%A3%E5%85%83.pdf?sign=vSi75kR1miGJudzGBflpV1SPcE7FHVeD0469GNNHo5E%3D%3A0",
            hrefName: "查看",
          },
           {
            name: `SylixOS技术分享与赛题解读`,
            age: "李孝成",
            href3:
              "https://pan.educg.net/api/v3/file/get/3867/%E6%93%8D%E4%BD%9C%E7%B3%BB%E7%BB%9F%E5%A4%A7%E8%B5%9B%E8%B5%9B%E9%A2%98PPT-%E6%9D%8E%E5%AD%9D%E6%88%90.pdf?sign=pIM3dZa4YDmmu8eJQCgLni-RAQeR5PGtaRjBneiPHiE%3D%3A0",
            hrefName: "查看",
          },
           {
            name: `应用内核的发展和挑战`,
            age: "谈鉴锋",
            href3:
              "https://pan.educg.net/#/s/JGWTX",
            hrefName: "下载",
          },
       
           

        ],
      },
 {
        title: "2021年全国大学生计算机系统能力大赛 操作系统设计赛技术培训（第三场）",
        time: "2021年3月21日14:30-18:00",
        img: "",
        href1: "https://mp.weixin.qq.com/s/TIo4a6q_-c_a6RAUGjMmqg",
        href2: "https://www.bilibili.com/video/BV1sK4y1o7k2",
        dataColumns: [
          {
            name: `计算机系统教学与研究的一些体会`,
            age: "陈海波",
            href3:
              "https://pan.educg.net/#/s/zZbcO",
            hrefName: "下载",
          },
           {
            name: `RT-Thread Smart在RISC-V平台的移植`,
            age: "李志锐",
            href3:
              "https://pan.educg.net/#/s/ZW3HR",
            hrefName: "下载",
          },
           {
            name: `太素OS开发历程`,
            age: "张根",
            href3:
              "https://pan.educg.net/#/s/WkBf5",
            hrefName: "下载",
          },
           {
            name: `处理器芯片发展新趋势：开源芯片`,
            age: "包云岗",
            href3:
              "https://pan.educg.net/api/v3/file/get/3860/20210328-%E5%A4%84%E7%90%86%E5%99%A8%E8%8A%AF%E7%89%87%E5%8F%91%E5%B1%95%E6%96%B0%E8%B6%8B%E5%8A%BF-%E5%BC%80%E6%BA%90%E8%8A%AF%E7%89%87-OS%E7%AB%9E%E8%B5%9B.pdf?sign=8xyqDO-RXluyzURH46aToLLyk9FYCa2w0XC0-4X5nE8%3D%3A0",
            hrefName: "查看",
          },
    
           

        ],
      },
       {
        detail:'https://mp.weixin.qq.com/s/UldUUiNyd4hCkuw7vk1sMw',
        title: "【直播】操作系统大赛技术报告会：开源社区的汇聚",
        time: "",
        img: "",
        href1: "",
        href2: "",
        dataColumns: [ ],
      },
       {
        title: "2021全国大学生操作系统比赛的研讨会",
        time: "",
        over:'（会议已结束）',
        img: "",
        href1: "",
        href2: "",
        dataColumns: [
          {
            time:'08:50',
            name: `致欢迎辞`,
            age: "中科院计算所领导和OS比赛组委会领导等",
            href3:
              "",
            hrefName: "查看",
          },
           {
             time:'09:00',
            name: `用户态中断在果壳处理器上的实践`,
            age: "王凯帆（国科大研究生）",
            href3:
              "https://pan.educg.net/#/s/VGRhq",
            hrefName: "下载",
          },
           {
             time:'09:30',
            name: `伏羲：一个基于RISC-V的全系统(CPU/OS/Compiler)实现`,
            age: "邢其正(北大研究生)",
            href3:
              "https://pan.educg.net/#/s/o47H1",
            hrefName: "下载",
          },
           {
             time:'10:00',
            name: `OS赛道、OS测试系统与OS测试用例介绍`,
            age: "王雷 （北航老师）+ 陈渝（清华老师）+ 萧络元（清华工程师）",
            href3:
              "https://pan.educg.net/#/s/JdZTX",
            hrefName: "下载",
          },
           {
             time:'10:30',
            name: `茶歇`,
            age: "",
            href3:
              "",
            hrefName: "下载",
          },
          {
            time:'11:00',
            name: `PKE OS课程实验设计`,
            age: "邵志远（华中老师）",
            href3:
              "https://pan.educg.net/#/s/zd8HO",
            hrefName: "下载",
          },
           {
             time:'11:30',
            name: `xv6 on K210的移植`,
            age: "车春池(华中本科生)",
            href3:
              "https://pan.educg.net/#/s/Zl5uR",
            hrefName: "下载",
          },
           {
             time:'12:00',
            name: `中午休息`,
            age: "",
            href3:
              "",
            hrefName: "下载",
          },
           {
             time:'13:30',
            name: `RISCV-64的硬件模拟器设计与实现`,
            age: "李旸（工程师/北航研究生）",
            href3:
              "111",
            hrefName: "下载",
          },
           {
             time:'14:00',
            name: `为果壳和SiFive等RV硬件移植定制Linux操作系统的经验与思考`,
            age: "周鹏（中科院软件所工程师）",
            href3:
              "https://pan.educg.net/#/s/Wo4t5",
            hrefName: "下载",
          },
           {
             time:'14:30',
            name: `ucore on K210的移植`,
            age: "宫晓利（南开老师）和学生",
            href3:
              "https://pan.educg.net/#/s/aLMC3",
            hrefName: "下载",
          },
             {
             time:'15:00',
            name: `茶歇`,
            age: "",
            href3:
              "",
            hrefName: "下载",
          },
             {
             time:'15:30',
            name: `rcore on qemu/k210设计与实现`,
            age: "吴一凡（清华助研）",
            href3:
              "https://pan.educg.net/#/s/4VXir",
            hrefName: "下载",
          },
             {
             time:'16:00',
            name: `Rust-based hypervisor的设计与实现`,
            age: "贾跃凯（清华研究生）",
            href3:
              "https://pan.educg.net/#/s/k20S5",
            hrefName: "下载",
          },
             {
             time:'16:30',
            name: `RustSBI的设计与实现`,
            age: "蒋周奇(华中本科生)",
            href3:
              "https://pan.educg.net/#/s/0eaUK",
            hrefName: "下载",
          },
             {
             time:'17:00',
            name: `rust xv6 on RV的设计与实现`,
            age: "陈恒杰（哈工大）",
            href3:
              "https://pan.educg.net/#/s/lgwI9",
            hrefName: "下载",
          },
           

        ],
      },
    

      ]
  },
  methods: {
    callback(key) {
      console.log(key);
    },
  },
};
</script>
<style scoped>
div,
p {
  padding: 0;
  margin: 0;
}
.tabs-tabpane-width {
  text-align: left;
  padding: 10px;
}
.look-del {
  font-size: 16px;
  color: #000;
  font-weight: 700;
  padding-top: 20px;
}
.look-title {
  margin-bottom: 20px;
  padding-top: 0;
}
.look-a {
  font-size: 12px;
  font-weight: 700;
  margin-left: 10px;
}
/deep/ .anticon {
  color: #0367f3;
}
.pad-left {
  padding: 5px 0;
  padding-left: 13px;
  text-indent: -13px;
}
.look-top {
  margin-top: 20px;
}
ul li {
  text-indent: 2em;
}
.pad-index {
  text-indent: 2em;
  padding: 5px 0;
}
.add-img img,
.tab-img {
  width: 80%;
  display: block;
  margin: 0 auto;
}
.tab-imgD {
  width: 50%;
  display: block;
}
.addLi li,
.addLia li {
  text-indent: 0;
  margin-bottom: 10px;
}
.addLi li h3 {
  margin-bottom: 0;
}
.addLi ul {
  padding-left: 6px;
}
.addLia ul {
  padding-left: 17px;
}
/* /deep/ .ant-tabs-tabpane-active{
  min-height:820px;
} */
/deep/ .ant-list-item {
  padding: 10px 0;
}
</style>