<template>
  <div>
    <heade currentrStr="" ></heade>
    <a-breadcrumb class='common-width'>
      <a-breadcrumb-item><a  href="javascript:;" @click='to("/")'>上一页</a></a-breadcrumb-item>
      <a-breadcrumb-item
        ><a href="">{{ name }}</a></a-breadcrumb-item
      >
    </a-breadcrumb>
    <tabDs :name="name" v-if="name.indexOf('2020')!=-1|| name.indexOf('2021')!=-1 "></tabDs>

    <tabDsJson v-else style="padding-bottom:10px;border-bottom: 7px solid rgb(30, 128, 255);" :getData="getData" ></tabDsJson>
    <div class="box bottom common-width" v-if='news.length!=0'>
      <div style="margin-top: 30px">
        <p class="banner-title title-w">
          新闻
          <a-icon
            type="file"
            theme="twoTone"
            two-tone-color="#01c7ff"
            style="margin-left: 5px"
          />
        </p>
      </div>
    </div>
    <div class="card-grid common-width">
      <a-card v-for="(item, index) in news" :key="index" :title="item.title">
        <p style="padding: 0 10px 5px;margin-bottom:0;">
          <a
            slot="extra"
            target="_blank"
            :href="item.url"
            style="font-size: 12px"
            >查看详情</a
          >
        </p>
      </a-card>
    </div>
    <a-back-top />
  </div>
</template>
<script>
import heade from "@/components/header.vue";
import tabDs from "@/components/oldDs.vue";
import tabDsJson from "@/components/tabDs.vue";
export default {
  data() {
    return {
      name: "",
      getData:[],
      news: [],
    };
  },
  mounted() {
    this.name = this.$route.query.name;
    this.initTab()
    this.newsFun(this.name)
  },
  components: {
    tabDs,
    tabDsJson,
    heade,
  },
  methods:{
    newsFun(title) {
      let data = {
        op: "indexnews",
        title: title,
      };
      let _this = this;
      this.$ajax
        .post(this.url + "/news.do", _this.Qs.stringify(data))
        .then(function (response) {
          console.log(response);
          if (response.data.value == "ok") {
            _this.news = response.data.data;
       
          } else {
            // _this.$message.info(response.data.msg);
            console.log(response.data.msg);
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    initTab(){
      let data = {
        op: "tablist",
        title:this.$route.query.name,
      };
      let _this = this;
      this.$ajax
        .post(this.url + "/news.do", _this.Qs.stringify(data))
        .then(function (response) {
          console.log(response);
          if (response.data.value == "ok") {
            // _this.$router.push({ path:response.data.data });
            // window.location.href= response.data.data
            _this.getData = response.data.data
            console.log(  _this.getData)
            // console.log(response.data.data.parent);
          } else {
            // _this.$message.info(response.data.msg);
             _this.$message.error(response.data.msg);
          }
        })
        .catch(function (error) {
          _this.$message.error('接口数据异常');
          console.log(error);
        });
    },
       to(url) {
      this.$router.push({ path: url });
    },
  }
};
</script>
<style scoped>
/deep/ .ant-card-head {
  padding: 0 5px;
  border-bottom: 0;
  min-height: 38px;
}
/deep/ .ant-card-head-title {
  font-size: 12px;
  padding: 10px 0;
}
/deep/ .ant-card-body {
  padding: 0;
  text-align: right;
}
/deep/ .ant-card-bordered {
  border-top: 2px solid #01c7ff;
  padding: 10px 0 !important;
  background: #f5fcff;
}
.news .title-w {
  padding: 5px 0;
}.banner-title {
  text-align: left;
  font-size: 24px;
  font-weight: bold;
  color: #000;
  width: 400px;
}.card-grid {
  /* max-width: 1240px; */
  margin: 5px auto !important;
  display: flex;
  /* justify-content: flex-start; */
  flex-wrap: wrap;
}
.card-grid div {
 margin: 10px .2% !important;
    margin-bottom: 15px;
    width: 24.6%;
}/deep/ .ant-breadcrumb {

  margin: 30px auto !important;
  padding: 10px;
  text-align: left;
}
</style>