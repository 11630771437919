<template>
     <div>

      <el-tabs v-model="activeName" @tab-click="callback" class='common-width'>
      
        <el-tab-pane :label="item.title" :name="item.sort+''" v-for="(item,index) in getData" :key="index">
       <div class="tabs-tabpane-width">
        <!-- <iframe src="https://eval.t.educg.com/inform.html?id=6372f14d540a665f753cea8b"></iframe> -->
        <VueMarkdown :source="item.content"  class="articalContent markdown-body" v-if="item.url==''"></VueMarkdown>
        <iframe v-else :src="item.url"></iframe>

        
        <!-- <p style='position: relative;'>
        <img src='../images/bianyi_banner.jpeg' width='100%' style="margin-bottom: 10px"/>
        <a href='https://www.educg.net/onlinecourse.html' target="_blank"><el-button type="primary"        size="small" style='position: absolute;bottom:20px;right:10px;'>在线开课</el-button></a>
        </p>
             <p class="pad-index">全国大学生计算机系统能力大赛（以下简称“大赛”）是由系统能力培养研究专家组发起、由全国高校计算机教育研究会主办、面向高校大学生的全国性大赛。</p>
             <p class="pad-index">大赛目标是以学科竞赛推动专业建设和计算机领域创新人才培养体系改革，培育我国高端芯片、关键基础软件的后备人才。大赛鼓励学生设计、实现综合性的计算机系统，培养系统级的设计、分析、优化与应用能力，提升学生的技术创新、工程实践、团队协作能力。大赛服务国家人才培养战略，以赛促学、以赛促教，为高水平计算机人才成长搭建交流、展示、合作的开放平台。</p>
              <p class="pad-index">编译系统设计赛要求各参赛队综合运用各种知识（包括但不局限于编译技术、操作系统、计算机体系结构等），构思并实现一个综合性的编译系统，以展示面向特定目标平台的编译器构造与编译优化的能力。</p>
              <p class="pad-index">为保证大赛顺利进行，全国大学生计算机系统能力培养大赛在机械工业出版社华章分社（北京华章图文信息有限公司）设立大赛秘书处;大赛下设指导委员会、技术委员会、评审委员会、监督委员会，负责指导、执行和监督大赛的组织、运营和奖项评审工作;本次大赛分为初赛和决赛两个阶段，初赛胜出者则有资格参加决赛。</p>
               <p class="pad-index">欢迎同学们踊跃报名参赛！</p>
            <p class="look-del">
              全国总决赛获奖结果
              <a href="javascript:;" class="look-a" @click="to('./chDetail')"
                >查看详情 》</a
              >
            </p> -->
          </div>
        </el-tab-pane>
        <!-- <el-tab-pane label="大赛章程与通知" name="second">
        <div class="tabs-tabpane-width">
            <p class="look-del look-title">
              2022全国大学生计算机系统能力培养大赛编译系统设计赛的信息
            </p>
            <a-list item-layout="horizontal" :data-source="data" :split="false" class='teshu'>
              <a-list-item slot="renderItem" slot-scope="item" >
                <a-list-item-meta description="" >
                  <a slot="title" :href="item.href" target="_blank">
                    <a-icon type="link" /><span style="margin-left:10px;">{{
                      item.title
                    }}</span>
                  </a>
                </a-list-item-meta>
              </a-list-item>
            </a-list>
          </div>
        </el-tab-pane>
        <el-tab-pane label="大赛要求与比赛内容" name="third">
        <div class="tabs-tabpane-width">
            <p class="look-del look-title">竞赛要求:</p>
            <div>
              <p class="pad-left">
                1. 编译器目标平台： 树莓派4B（ARM Cortex-A72 CPU，2GB LPDDR4
                SDRAM 内存） ，报名截止后，大赛组委会给参赛队伍邮寄树莓派。
                <a
                  target="_blank"
                  href="https://gitlab.eduxiji.net/nscscc/compiler2021/-/blob/master/%E6%A0%91%E8%8E%93%E6%B4%BE%E7%9B%B8%E5%85%B3%E5%BC%80%E5%8F%91%E6%96%87%E6%A1%A3.pdf"
                  >树莓派相关开发文档.pdf</a
                >。 同时推荐大家使用华为云鲲鹏服务器进行开发。
              </p>
              <p class="pad-left">
                2. 语言定义与竞赛要求：
                <a
                  target="_blank"
                  href="https://gitlab.eduxiji.net/nscscc/compiler2022/-/blob/master/SysY2022%E8%AF%AD%E8%A8%80%E5%AE%9A%E4%B9%89-V1.pdf"
                  >语言定义</a
                >、
                <a
                  target="_blank"
                  href="https://gitlab.eduxiji.net/nscscc/compiler2022/-/blob/master/SysY2022%E8%BF%90%E8%A1%8C%E6%97%B6%E5%BA%93-V1.pdf"
                  >运行时库</a
                >。
              </p>
              <p class="pad-left">
                3.
                git协作：要求使用git进行协作开发，登录之后，点击导航栏的“gitlab”即可直接登录gitlab，
                <a
                  target="_blank"
                  href="https://gitlab.eduxiji.net/nscscc/compiler2021/-/blob/master/GitLab%E5%9F%BA%E7%A1%80%E4%BD%BF%E7%94%A8%E6%96%B9%E5%BC%8F.pdf"
                  >代码托管平台使用文档与注册规范 </a
                >。
              </p>
              <p class="pad-left">
                4.
                <a
                  target="_blank"
                  href="https://gitlab.eduxiji.net/nscscc/compiler2021/-/blob/master/GitLab%E5%9F%BA%E7%A1%80%E4%BD%BF%E7%94%A8%E6%96%B9%E5%BC%8F.pdf"
                >
                  系统运行时库、 功能测试用例、 性能测试用例</a
                >。
              </p>
              <p class="pad-left">
                5.
                <a
                  target="_blank"
                  href="https://gitlab.eduxiji.net/nscscc/compiler2021/-/blob/master/%E5%A4%A7%E8%B5%9B%E6%8F%90%E4%BA%A4%E8%AF%B4%E6%98%8Ev2.pdf"
                >
                  大赛提交说明</a
                >。
              </p>
            </div>
            <p class="look-del look-title look-top">
              比赛内容: 开发支持特定语言、面向ARM硬件平台的综合性编译系统
            </p>
            <div>
              <p class="pad-left">
                1.基于C、C++、Java语言开发，能够在Ubuntu18.04(64位)操作系统的x86评测服务器上编译运行。
              </p>
              <p class="pad-left">
                2.
                能够将符合自定义程序设计语言SysY2022的测试程序编译为ARM汇编语言程序（32位，ARMv7）。
              </p>
              <p class="pad-left">
                3. 通过在Raspbery 4B
                上运行汇编链接后的二进制程序，测试程序功能的正确性和运行效率，来评价参赛队开发的编译器的功能正确性和优化效果。
                <img
                  style="display: inline-block"
                  src="../images/neirong.png"
                  class='tab-img'
                />
              </p>
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane label="测试用例征集" name="four">
              <div class="tabs-tabpane-width">
            <p class="look-del  look-title">
              2022年全国大学生系统能力大赛编译系统设计赛（华为毕昇杯）已启动报名工作。为更好地组织好本届大赛，并通过大赛积累更多优秀、实用的编译课程教学资源，现面向全国征集比赛测试用例。相关要求如下：
            </p>
            <p class="pad-left">
              1.测试用例提供者可以为高校从事编译及相关课程的教师、研究生，企业技术人员或其他愿意提供或参与测试用例开发的人士。
            </p>
            <p class="pad-left">
              2.所提供的测试用例需符合下述技术要求：
              <ul>
                <li>用 SysY2022 编写。（SysY为大赛使用的文法，C语言子集，没有指针。）用例可被gcc-7.5.0正确编译并执行，建议在普通配置的台式PC上的编译时间不超过20秒。</li>
              <li>测试用例的设计最好能够针对某些编译优化策略，例如寄存器分配、指令调度、公共子表达式消除、矩阵变换等。</li>
               <li>提供至少三组测试点数据，输入数据集应在规模或特征上存在差异。考虑到评测需求，程序应有输出结果以进行正确性比对。</li>
                <li>评测程序在gcc-7.5.0 -O2优化生成后的程序在普通配置台式PC上的运行时间建议不超过300秒。</li>
              </ul>
            </p>
            <p class="pad-left">3.参与提供测试用例者需遵守大赛保密要求（与大赛秘书处签署保密协议）。</p>
            <p class="pad-left">4.若测试用例经大赛组委会审核通过并采用，将向测试用例提供者颁发“组委会特别贡献奖”证书。大赛组委会也将持续从大赛中整理出相应的教学素材，分享给高校教师和学习者。</p>
          </div>
            <p class="look-del">
              欢迎大家积极参与并分享本次征集工作！  <a   target="_blank" href="https://course.educg.net/sv2/indexexp/contest/contest.jsp?contestID=xY_ajameK7k&tabDocID=9044437&my=false&contestCID=0#contestSubAn" class="look-a"
                >了解更多 》</a
              >
            </p>
        </el-tab-pane>
        <el-tab-pane label="技术报告" name="five">  
          <div class="tabs-tabpane-width">
               <p class="look-del look-title">2022全国大学生编译系统设计赛系列技术培训时间表<a   target="_blank" href="https://mp.weixin.qq.com/s/VF5gdUaVod-aI3YmmPHq0g" class="look-a"
                >更多信息 》</a
              ></p>
                 <p >2022年全国大学生计算机系统能力大赛编译系统设计赛（华为毕昇杯）已进入紧张的初赛阶段。为了帮助各参赛队系统地学习和梳理编译技术的知识、拓展视野、更好地完成作品，大赛技术委员会经过调研，听取往届参赛队的意见，定于2022年6月4日开始，组织为期9周的系列技术培训。时间安排与主题如下所示：</p>
                  <a-table bordered :columns="columns" :data-source="dataColumns" style='margin-top:10px;' :pagination='false'     :scroll="{ y: 240 }"></a-table>
                   <p class="look-del ">前7次培训的大致安排如下：</p>
                  <p class="pad-left">1.由每个主题的负责人进行知识串讲。部分知识点会邀请国内高校、科研院所从事相关工作的其他老师进行深入介绍。</p>
                   <p class="pad-left">2.华为专家从工程角度介绍相关主题的应用与实践。</p>
                  <p class="pad-left">3.往届优秀参赛队结合主题进行分享。</p>
                  <p class="pad-left">4.交流互动与答疑。</p>
                  <p class="look-del ">2022编译系统设计赛（华为毕昇杯）:</p>
                   <a href="JavaScript:;" @click="to('./sai?id=a')">初赛综合答疑会</a>、 <a href="JavaScript:;" @click="to('./sai?id=b')">第七场技术培训</a>、 <a href="JavaScript:;" @click="to('./sai?id=c')">第六场技术培训</a>、 <a href="JavaScript:;" @click="to('./sai?id=d')">第五场技术培训</a>、 <a href="JavaScript:;" @click="to('./sai?id=e')">第四场技术培训</a>、 <a href="JavaScript:;" @click="to('./sai?id=f')">第三场技术培训</a>、 <a href="JavaScript:;" @click="to('./sai?id=f')">第二场技术培训</a>、 <a href="JavaScript:;" @click="to('./sai?id=g')">第一场技术培训</a>、 <a href="JavaScript:;" @click="to('./sai?id=h')">技术培训会</a>
                  

             </div>
        </el-tab-pane>
        <el-tab-pane label="作品开源" name="six">
                 <div class="tabs-tabpane-width">
               <p class="look-del look-title">参赛作品开源地址</p>
               <a-table bordered :columns="columnsK" :data-source="dataK" style='margin-top:10px;' :pagination='false'  :scroll="{ y: 425 }">
                                <a slot="action" slot-scope="item" :href="item" target="_blank" >{{item}}</a>

               </a-table>

            </div>
        </el-tab-pane>
        <el-tab-pane label="技术支持" name="seven">
                     <div class="tabs-tabpane-width">
               <p class="pad-left">更多培训视频：<a   target="_blank" href="https://space.bilibili.com/1533001983?spm_id_from=333.337.0.0" 
                >计算机系统能力大赛</a></p>
                    <p class="pad-left">参赛队员官方讨论区：<a   target="_blank" href="https://gitlab.eduxiji.net/nscscc/discussion" 
                >https://gitlab.eduxiji.net/nscscc/discussion</a></p>
                   <p class="pad-left">参赛队员官方技术支持QQ群：<a   target="_blank" href="https://qm.qq.com/cgi-bin/qm/qr?k=A8HT6ffhc2H7ng7XEJ8pDnntlBgUKRQs&authKey=%2BERv6TPdGkVVZdoV93Eksz8sLF3T5Un14PcLlRttdxs9d%2B8ynNSOdQ%3D%3D&group_code=700621252" 
                >700621252</a> 【请注明：学校+姓名】，在群内即时获取技术支持、了解大赛信息。</p>
                  <p class="pad-left">指导教师QQ群：<a   target="_blank" href="https://qm.qq.com/cgi-bin/qm/qr?k=Q6SgrbBJHkBW60TJaYPzCNTOzRnZ-hf1&authKey=4lMK%2FNf2Qv42%2B%2BejxcA1SlezR4Z3Y8%2FbTK6mqXD4IdEOQcINq%2FopXg%3D%3D&group_code=719489238" 
                >719489238</a> 【仅限 高校教师，请注明：学校+姓名】，了解大赛信息，平台开课。</p>
                 <p class="look-del look-title">软件开发工具与设计资料</p>
                   <p class="pad-left">  <a target="_blank" href="https://gitlab.eduxiji.net/nscscc/compiler2022/-/blob/master/SysY2022%E8%AF%AD%E8%A8%80%E5%AE%9A%E4%B9%89-V1.pdf" 
                >1.SysY2022 程序设计语言规范、文法及说明</a> </p>
                    <p class="pad-left"><a target="_blank" href="https://gitlab.eduxiji.net/nscscc/compiler2021/-/blob/master/%E6%A0%91%E8%8E%93%E6%B4%BE%E7%9B%B8%E5%85%B3%E5%BC%80%E5%8F%91%E6%96%87%E6%A1%A3.pdf" 
                >2.树莓派(Raspberry 4B)使用与开发指南</a></p>
                     <p class="pad-left"><a target="_blank" href="https://static.docs.arm.com/ddi0406/c/DDI0406C_C_arm_architecture_reference_manual.pdf">3.ARM_Architecture_Reference_Manual_ARMv7-A_and_ARMv7-R_edition</a></p>
                      <p class="pad-left"><a target="_blank" href="https://gitlab.eduxiji.net/nscscc/compiler2021/-/blob/master/ARM%E5%92%8CThumb-2%E6%8C%87%E4%BB%A4%E9%9B%86%E5%BF%AB%E9%80%9F%E5%8F%82%E8%80%83%E5%8D%A1.pdf">4.ARM和Thumb-2指令集快速参考卡</a></p>
                       <p class="pad-left"><a target="_blank" href="https://c9x.me/compile/bib/">5.Resources for Amateur Compiler Writers</a></p>
                        <p class="pad-left"><a target="_blank" href="https://pan.educg.net/#/s/V2oiq?path=%2F">6.编译大赛评测镜像</a></p>
                         <p class="pad-left"><a target="_blank" href="https://pan.educg.net/#/s/leAC9/pdf?name=%E5%85%B3%E4%BA%8E%E9%9A%90%E8%97%8F%E6%B5%8B%E8%AF%95%E7%94%A8%E4%BE%8B%E7%9A%84%E6%8F%90%E7%A4%BA.pdf">7.关于隐藏测试用例的提示</a></p>
                 
                 
                 
      
                </div>
        </el-tab-pane>
        <el-tab-pane label="等级评定标准" name="eight">  
          <div class="tabs-tabpane-width">
            <p class="look-del">
              系统能力培养大赛-编译系统设计赛等级评定方案
            </p>
            <p class="pad-left del-pad">
              第1条 编译系统设计赛根据队伍最后提交的作品进行等级评定，共设四级，并发放等级证书。四个等级所代表的能力情况及评定标准说明如下：
              <ul>
                <li>一级：熟悉编译器结构和构建过程，熟练掌握绝大部分基本优化技巧，在给定测例上优化能力达到或超过与GCC编译器的“O2”优化相当的水平。</li>
              <li>标准：必过功能用例全部通过，且性能分 ≥ 90分。</li>
              <li style="list-style:none;"><br/></li>
              <li>二级：能够完成编译器设计及实现，功能正确，熟悉编译器结构和构建过程，具有一定的编译优化能力。</li>
              <li>标准：必过功能用例全部通过，且90 > 性能分 ≥ 40分。</li>
              <li style="list-style:none;"><br/></li>
              <li>三级：熟悉编译器结构和构建过程，能够开发实现功能基本正确的编译器。</li>
              <li>标准：未达到二级标准，但是功能分>= 60。</li>
              <li style="list-style:none;"><br/></li>
              <li>入门级：基本熟悉编译器结构和构建过程，能够开发实现具有一定功能的编译器。</li>
              <li>标准：未达到三级标准，编译通过，能通过不少于5个功能用例。</li>
              <li style="list-style:none;"><br/></li>
          
              </ul>
            </p>
            <p class="pad-left del-pad">
              第2条 评级用例与参赛作品。
              <ul>
                <li>评级用例：评级用例包括功能用例与性能用例，与当年决赛阶段一致。功能用例中必过部分全部通过后即可进行性能测试。</li>
              <li>参赛作品：以队伍在全赛程中最后一次提交为准，未提交的不进行评定。</li>
 
          
              </ul>
            </p>
            <p class="pad-left del-pad">第3条 功能得分。满分100。评级功能测试用例每通过1%得1分。</p>
            <p class="pad-left del-pad">第4条 性能得分。基于评级性能测试用例，以GCC 7.5.0版本的“O2”选项优化运行时间为基准。参赛队在单个性能测试用例的得分为：100 * (GCC编译用例运行时间 / 参赛队用例运行时间）。性能总分为所有性能测试用例得分的几何平均值。评级结果依据性能得分排名。</p>
            <p class="pad-left del-pad">第5条 等级证书内容:
              <ul>
              <li>个人和队伍信息及参赛情况。</li>
              <li>功能分、性能分及用例通过情况</li>
              <li>队伍成绩在当年参赛队伍的排名百分比及历届所有队伍中的排名百分比。</li>
              <li>大赛网站可以查询电子版等级证书。</li>
            </ul>
            </p>
            <p class="pad-left del-pad">第6条 等级认定结果，待决赛结束后公布。</p>


          </div>
        </el-tab-pane>
        <el-tab-pane label="常见问题" name="nine">  
          <div class="tabs-tabpane-width">
            <p class="look-del">
              更多大赛常见问题：
              <a href="https://course.educg.net/sv2/indexexp/contest/contest.jsp?contestID=pm0oS_aF0Lg&tabDocID=15058533" class="look-a"
                >查看详情 》</a
              >
            </p>
          </div>
        </el-tab-pane> -->
  </el-tabs>
    </div>
</template>
<script>
const data = [
  {
    title: "2022全国大学生计算机系统能力大赛-编译系统设计赛通知",
    href: "https://gitlab.eduxiji.net/nscscc/compiler2022/-/blob/master/%E7%BC%96%E8%AF%91%E7%B3%BB%E7%BB%9F%E8%AE%BE%E8%AE%A1%E8%B5%9B%E9%80%9A%E7%9F%A5.pdf",
  },
  {
    title: "2022全国大学生计算机系统能力培养大赛——章程",
    href: "https://gitlab.eduxiji.net/nscscc/compiler2022/-/blob/master/%E7%BC%96%E8%AF%91%E7%B3%BB%E7%BB%9F%E8%AE%BE%E8%AE%A1%E8%B5%9B%E7%AB%A0%E7%A8%8B.pdf",
  },
  {
    title: "2022全国大学生计算机系统能力大赛-编译系统设计赛技术方案",
    href: "https://mp.weixin.qq.com/s/fhU_4HbHaUk8iuWkHpimFw",
  },
];

const columns = [
  { title: "时间", dataIndex: "name", key: "name" },
  { title: "培训主题", dataIndex: "age", key: "age" },
  { title: "负责人", dataIndex: "address", key: "1" },
];
const dataColumns = [
  {
    name: `6月4日`,
    age: "编译技术基础",
    address: `张莉（北京航空航天大学）`,
  },
  {
    name: `6月11日`,
    age: "编译框架与CI搭建",
    address: `黄林鹏（上海交通大学）`,
  },
  {
    name: `6月18日`,
    age: "文法与前端设计",
    address: `张昱（中国科学技术大学）`,
  },
  {
    name: `6月25日`,
    age: "中间语言及SSA表示",
    address: `刘先华（北京大学）`,
  },
  {
    name: `7月2日
`,
    age: "中间代码优化",
    address: `冯晓兵（中科院计算所）`,
  },
  {
    name: `7月9日`,
    age: "ARM架构与体系结构相关优化",
    address: `计卫星（北京理工大学）`,
  },
  {
    name: `7月16日`,
    age: "高级编译优化技术",
    address: `翟季冬（清华大学）`,
  },
  {
    name: `7月23日`,
    age: "初赛综合答疑（一）",
    address: `大赛技术组`,
  },
  {
    name: `7月30日`,
    age: "初赛综合答疑（二）",
    address: `大赛技术组`,
  },
];
const columnsK = [
  { title: "学校", dataIndex: "name", key: "name" },
  { title: "队伍名称", dataIndex: "age", key: "age" },
  {
    title: "源码仓库地址",
    dataIndex: "address",
    key: "1",
    scopedSlots: { customRender: "action" },
  },
];
const dataK = [
  {
    name: `清华大学`,
    age: "赫露艾斯塔",
    address: `https://gitlab.eduxiji.net/educg-group-12619-928705/helesta`,
  },
  {
    name: `北京航空航天大学`,
    age: "喵喵队仰卧起坐",
    address: `https://gitlab.eduxiji.net/educg-group-12619-928705/compiler2022-meowcompiler`,
  },
  {
    name: `清华大学`,
    age: "啊对对队",
    address: `https://gitlab.eduxiji.net/educg-group-12619-928705/compiler2022-meowcompiler`,
  },
  {
    name: `北京理工大学`,
    age: "HexonZ",
    address: `https://gitlab.eduxiji.net/educg-group-12619-928705/cbias`,
  },
  {
    name: `复旦大学`,
    age: "编译",
    address: `https://gitlab.eduxiji.net/educg-group-12619-928705/penguincompiler`,
  },
  {
    name: `国防科技大学`,
    age: "嘉然今天偷着乐",
    address: `https://gitlab.eduxiji.net/educg-group-12619-928705/RaVincent-2379`,
  },
  {
    name: `哈尔滨工业大学（深圳）`,
    age: "萝杨空队",
    address: `https://gitlab.eduxiji.net/educg-group-12619-928705/ssyc`,
  },
  {
    name: `南开大学`,
    age: "NKUER4",
    address: `https://gitlab.eduxiji.net/educg-group-12619-928705/1911463-188`,
  },
  {
    name: `西北工业大学`,
    age: "从容应队",
    address: `https://gitlab.eduxiji.net/educg-group-12619-928705/HammerWang-2412`,
  },
  {
    name: `北京航空航天大学`,
    age: "（编译（编译（编译器）器）器）队",
    address: `https://gitlab.eduxiji.net/educg-group-14157-894146/compiler`,
  },
  {
    name: `北京交通大学`,
    age: "碰瓷的大白菜",
    address: `https://gitlab.eduxiji.net/educg-group-12619-928705/end_3/edit`,
  },
  {
    name: `北京邮电大学`,
    age: "无色透明队",
    address: `https://gitlab.eduxiji.net/educg-group-12619-928705/mercuri-v2`,
  },
  {
    name: `电子科技大学`,
    age: "变异开始",
    address: `https://gitlab.eduxiji.net/educg-group-14158-894147/compiler`,
  },
  {
    name: `东北大学`,
    age: "恩毅优肯派勒",
    address: `https://gitlab.eduxiji.net/educg-group-12619-928705/SysYCompiler`,
  },
  {
    name: `哈尔滨工业大学（深圳）`,
    age: "lrc",
    address: `https://gitlab.eduxiji.net/educg-group-12619-928705/csc-3927`,
  },
  {
    name: `华南理工大学`,
    age: "bddd",
    address: `https://gitlab.eduxiji.net/educg-group-12619-928705/bddd`,
  },
  {
    name: `华中科技大学`,
    age: "WASD",
    address: `https://gitlab.eduxiji.net/educg-group-12619-928705/cbysal-1605`,
  },
  {
    name: `西北工业大学`,
    age: "王力口乐队",
    address: `https://gitlab.eduxiji.net/educg-group-12619-928705/2019302804-2071`,
  },
  {
    name: `中国科学技术大学`,
    age: "魔法御姐",
    address: `https://gitlab.eduxiji.net/educg-group-12619-928705/magic_misaka`,
  },
  {
    name: `中国科学技术大学`,
    age: "和我签订契约，成为可执行文件！",
    address: `https://gitlab.eduxiji.net/educg-group-12619-928705/compiler2022-be_an_elf`,
  },
  {
    name: `中南大学`,
    age: "麓南双人组",
    address: `https://gitlab.eduxiji.net/educg-group-12619-928705/Devotes-419`,
  },
  {
    name: `北京交通大学`,
    age: "MoeCompiler",
    address: `https://gitlab.eduxiji.net/educg-group-12619-928705/compiler2022-moecompiler`,
  },
  {
    name: `北京理工大学`,
    age: "弓张编译器同好会",
    address: `https://gitlab.eduxiji.net/educg-group-12619-928705/yumiharakonnpaira`,
  },
  {
    name: `电子科技大学`,
    age: "没编译就没BUG",
    address: `https://gitlab.eduxiji.net/educg-group-12619-928705/ohhhh-3145`,
  },
  {
    name: `湖南大学`,
    age: "旋风巴别鱼",
    address: `https://gitlab.eduxiji.net/educg-group-12619-928705/april-2384`,
  },
  {
    name: `华中科技大学`,
    age: "编的不坏，译的也快",
    address: `https://gitlab.eduxiji.net/educg-group-12619-928705/t865486907-3026`,
  },
  {
    name: `浙江大学`,
    age: "thcc",
    address: `https://gitlab.eduxiji.net/educg-group-12619-928705/TimeOrange-3924`,
  },
  {
    name: `中山大学`,
    age: "Yat-CC: Yet another tiny C compiler",
    address: `https://gitlab.eduxiji.net/educg-group-12619-928705/sysu001-1557`,
  },
  {
    name: `重庆大学`,
    age: "LNK2022",
    address: `https://gitlab.eduxiji.net/educg-group-12619-928705/compiler`,
  },
  {
    name: `北京航空航天大学`,
    age: "LoveLive!Virtual Magic!",
    address: `https://gitlab.eduxiji.net/educg-group-12619-928705/CoolColoury-2778`,
  },
  {
    name: `北京航空航天大学`,
    age: "编不出来不起床",
    address: `https://gitlab.eduxiji.net/educg-group-12619-928705/MegaSysy`,
  },
  {
    name: `北京航空航天大学`,
    age: "又是困难的取名时间了",
    address: `https://gitlab.eduxiji.net/educg-group-12619-928705/wjh15101051-3196`,
  },
  {
    name: `北京航空航天大学`,
    age: "coredump :)",
    address: `https://gitlab.eduxiji.net/educg-group-12619-928705/Combinatorics-626`,
  },
  {
    name: `华中科技大学`,
    age: "自主可控编译器",
    address: `https://gitlab.eduxiji.net/educg-group-12619-928705/U201915084-1896`,
  },
  {
    name: `北京航空航天大学`,
    age: "lden Compiler",
    address: `https://gitlab.eduxiji.net/educg-group-12619-928705/elden-compiler`,
  },
  {
    name: `北京理工大学`,
    age: "正能量满满队",
    address: `https://gitlab.eduxiji.net/educg-group-12619-928705/1120191244-1813`,
  },
  {
    name: `北京理工大学`,
    age: "魏公村汽修厂",
    address: `https://gitlab.eduxiji.net/educg-group-12619-928705/LugerW-642`,
  },
  {
    name: `华中科技大学`,
    age: "HustSysy",
    address: `https://gitlab.eduxiji.net/educg-group-12619-928705/U201915125-268`,
  },
];
import VueMarkdown from 'vue-markdown'
// import marked from 'marked'

export default {
  props: {
    type: String,
    getData:Array,
    activeNamePr:String
  },
  components: {
    VueMarkdown
  },
  data() {
    return {
     
      columns,
      data,
      columnsK,
      dataK,
      dataColumns,
      activeName: '1',
      linshi:''
    };
  },
  mounted(){
    // debugger
    // if (this.$route.query.tab) {
    //   this.activeName = this.$route.query.tab;
    // }

  },
  watch:{
    activeNamePr(n,o){
      console.log(o,n)
      this.activeName = n;

    }
  },
  methods: {

    
    callback(key) {
      console.log(key);
      this.$emit('activeNameFun',this.activeName)
      // console.log(this.activeName)

    },
    to(url) {
      this.$router.push({ path: url });
    },
  },
};
</script>
<style scoped>
@import '../assets/markdown.css';
div,
p {
  padding: 0;
  margin: 0;
}
.tabs-tabpane-width .table{
  border-collapse:collapse;
  width: 80%;
    margin: 0 auto;
    border: 1px solid #ccc;
    border-radius: 4px;
}
.tabs-tabpane-width {
  text-align: left;
  padding: 0 10px;
  min-height:400px;
}
.look-del {
  font-size: 16px;
  color: #000;
  font-weight: 700;
  padding-top: 20px;
}
.look-title {
  margin-bottom: 20px;
  padding-top: 0;
}
.look-a {
  font-size: 12px;
  font-weight: 700;
  margin-left: 10px;
}
/deep/ .anticon {
  color: #0367f3;
}
/* .ant-tabs-line{
  width:1240px;
  margin:0 auto;
} */
.pad-left {
  padding: 5px 0;
  padding-left: 13px;
  text-indent: -13px;
}
.look-top {
  margin-top: 20px;
}
.teshu li {
  padding-left: 13px;
  text-indent: -13px;
  margin-left: 16px;
}
ul li {
  text-indent: 0.1em;
}
.pad-index {
  text-indent: 2em;
  padding: 5px 0;
}
/* /deep/ .ant-tabs-tabpane-active{
  min-height:820px;
} */
.tabs-tabpane-width img {
  width: 800px;
}
.table{}
.del-pad{
  padding-left: 40px;
  text-indent: -40px;
}
iframe{
  border:none;
  width:100%;
  min-height:600px;
}
/deep/ .ant-tabs-tabpane-inactive {
  min-height: 440px;
}
/deep/.ant-table-wrapper {
  border: 1px solid #ededed;
}
@media (max-width: 800px) {
  .tabs-tabpane-width img{
    width: 100%;
  }
}
</style>